import React from 'react';
import ReactCountryFlag from "react-country-flag"
import JsxHelper from './jsx';
import env from 'config/env';
import DateHelper from './date';

const displayRowStatus = (row) => {
	const status = getIPBanStatus(row);
	const banned = status === 'banned';
	const expired = status === 'expired';
	const statusText = banned ? 'Banned' : (expired ? 'Expired' : 'Reported Safe');
	const statusColor = banned ? 'danger' : (expired ? 'primary' : 'success');
	let tooltip = row.reported_safe ? row.metadata.reported_safe_reason : '';
	if (!row.reported_safe && !expired && row.expires_at) {
		tooltip = `Expires at ${row.expires_at || 'Unknown'} GMT`;
	}
	return JsxHelper.createBubble({
		text: statusText,
		background: statusColor,
		small: true,
		tooltip,
	});
}

const displayRiskLevel = (row) => JsxHelper.createBubble({
	text: row.high_risk ? 'High' : 'Normal',
	background: row.high_risk ? 'warning' : 'info',
	small: true,
	tooltip: row.high_risk
		? `High-risk IP addresses are banned at the server level by the ${env.getBrandShortName()} Firewall due to malicious activity. These IPs usually come from regions with high spam rates or have been reported for abuse. Because of the low false positive rate, site visitors cannot unban these IPs, and no error page will appear to verify human activity. Unbanning can only be done via the ${env.getBrandShortName()} dashboard.`
		: `Normal-risk IP addresses are banned at the server level by the ${env.getBrandShortName()} Firewall but are not flagged as high risk or through User-Defined Ban Rules in the ${env.getBrandShortName()} Hosting plugin. Site visitors will receive an error page where they can verify their activity and unban themselves.`
	})

const getIPBanStatus = (row) => {
	if (row.reported_safe) {
		return 'reported_safe';
	}
	const now = DateHelper.toUTCFormat();
	const expired = row.expires_at && row.expires_at < now;
	return expired ? 'expired' : 'banned';
}

const displayCountryFlag = (row) => {
  if ((!row.country_code && !row.country) || row.country_code === 'XX') {
    return 'N/A';
  }
  return <ReactCountryFlag
    className='flag'
    countryCode={row.country_code || row.country}
    title={row.country_code || row.country}
    style={{ fontSize: '2em', lineHeight: '1.3em', }}
  />
}

const httpResponseToColor = status => {
	let color = 'info';
  status = status.toString();
	if (status.startsWith('2')) {
	  color = 'success';
	} else if (status.startsWith('3')) {
	  color = 'info';
	} else if (status.startsWith('4')) {
	  color = 'warning';
	} else if (status.startsWith('5')) {
	  color = 'danger';
	}
	return color;
};

const httpResponseToText = status => {
  let text = 'Unknown';
  status = status.toString();
  if (status.startsWith('2')) {
    text = 'Success';
  } else if (status.startsWith('3')) {
    text = 'Redirect';
  } else if (status === '444') {
    text = 'No Response';
  } else if (status === '499') {
    text = 'Client Closed Request';
  } else if (status.startsWith('4')) {
    text = 'Client Error';
  } else if (status.startsWith('5')) {
    text = 'Server Error';
  }
  return text;
};

const FirewallHelper = {
  displayCountryFlag,
  displayRowStatus,
  getIPBanStatus,
  displayRiskLevel,
  httpResponseToColor,
  httpResponseToText,
};
  
  export default FirewallHelper;
  