import React, { Fragment } from 'react';
import DNSZoneService from '../services/dnsZone';
import { isEmpty } from 'helpers';
import JsxHelper from './jsx';
import DnsHelper from 'helpers/dnszone';
import env from 'config/env';

const delayPropagationTooltip = ipv4 => {
  return `Enable this option to inform the platform that your domain record has not yet propagated
			so the system will automatically issue the SSL certificate once it is linked to ${ipv4}.
			<br/>This may cause your website to be inaccessible until the domain record is propagated.`;
};

const isDefaultHostingDomain = domain => {
  return domain.includes(process.env.REACT_APP_DEFAULT_HOSTING_DOMAIN);
};

const isRegistrableDomain = rootDomain => {
  return (
    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(rootDomain) &&
    !rootDomain.startsWith('www.') &&
    !rootDomain.startsWith('http://') &&
    !rootDomain.startsWith('https://') &&
    !rootDomain.includes('/') &&
    !rootDomain.includes(':')
  );
};

const resolveSingleDNS = async (domain, expected, recordType) => {
  const results = await DNSZoneService.fetchDomain({
    domains: [ domain ],
    expected: expected || null,
    record_type: recordType || 'A'
  });
  return isEmpty(results) ? null : results[0];
}

const resolveDNS = async (domains, resolvedDomains, expected, recordType) => {
  let toResolve = [];
  const allResolvedDomains = resolvedDomains;
  for (const d of domains) {
    if (allResolvedDomains[d]) {
      continue; // Skip if already resolved
    }
    toResolve.push(d);
  }
  const resData = !isEmpty(toResolve) ? await DNSZoneService.fetchDomain({
    domains: toResolve,
    expected: expected || null,
    record_type: recordType || 'A'
  }) : [];
  return {
    res: resData,
    myResolvedDomains: allResolvedDomains,
  };
};

const scanRecords = async (domain, recordType) => {
  return await DNSZoneService.scanDomain({ domain: domain, record_type: recordType || null });
}

/* Table Headers */

const renderDnsManagerPrimaryButtonCell = (row, callback) => JsxHelper.createButton({
  label: row.is_default ? 'Primary' : 'Set As Primary',
  classes: row.is_default ? 'success--btn' : 'primary--btn',
  onClick: () => row.is_default ? null : callback(row),
  small: true,
});

const renderDnsManagerPointsTo = (status) => {
  if (!status) {
    return JsxHelper.createLoadingSpinner('', 20, '0 0 0 20px', '3px 0 0 0');
  }
  const isActive = status.is_active && status.is_dns_active;
  const color = isActive ? 'success' : 'warning';
  return <Fragment>
    <div>
      {(!isActive && status.auto_fixable)
        ? <div className='dns-auto-config'>
          {JsxHelper.createSuccessBox('Auto-configurable', { padding: '5px', fontSize: '12px', 'display': 'inline-block', fontWeight: 'bold' })}
          <span>{JsxHelper.createBasicTooltip('DNS record auto-configures in Cloudflare upon saving changes.')}</span>
        </div>
        : <div>
            <span>{status.points_to || '-'}</span>
            {JsxHelper.createBubble({
              icon: color,
              color,
              customClass: 'dns-points-to',
              tooltip: status.description,
            })}
            {status.warning && JsxHelper.createWarningBox(status.warning, true, '', { marginRight: '12px', })}
          </div>
      }
      
    </div>
  </Fragment>
}

const renderAddNSRecordsInstructions = (domain, records, dispatch) => <Fragment>
  <p className='step-title'>
    Point your <b>{domain}</b> domain to {env.getBrandShortName()} by changing its nameservers.
    Nameservers play a crucial role in the Domain Name System (DNS) by directing the global database to the correct location of a website.
    Follow the steps below to complete this process.
  </p>
  <div className='ns-steps'>
    <p>1. <strong>Find out</strong> the registrar of {<b>{domain}</b>} via {JsxHelper.createToolboxLink(domain)}.</p>
    <p>2. <strong>Log in</strong> to your account and go to the DNS Manager.</p>
    <p>3. <strong>Remove</strong> your old nameservers.</p>
    <div>
      <p>4. <strong>Add</strong> the following nameservers:<br/></p>
        <span style={{ width: '10%', display: 'inline-block' }}>
          {records.map(r => DnsHelper.normalizeNSValue(r)).map(nsValue => (
            <div className='ns-record' key={nsValue}>
              {JsxHelper.createCopyButton({ dispatch, value: nsValue, type: 'NS Record' })}
            </div>
          ))}
        </span>
    </div>
    <p>5. <strong>Save</strong> your changes and wait until the DNS is propagated.</p>
    <p className='step-note'>Note that it may take up to 24 hours for the changes to propagate.</p>
  </div>
</Fragment>

const domainHelper = {
  delayPropagationTooltip,
  isDefaultHostingDomain,
  resolveDNS,
  isRegistrableDomain,
  resolveSingleDNS,
  scanRecords,
  renderDnsManagerPointsTo,
  renderDnsManagerPrimaryButtonCell,
  renderAddNSRecordsInstructions,
};

export default domainHelper;
