import {
  SETTING_SET_ALL,
  SETTING_UPDATE,
  SETTING_WEBSITES_UPDATE,
  SETTING_SERVERS_UPDATE,
  SETTING_EMAIL_TEMPLATE_UPDATE,
  SETTING_CLIENT_REPORT_UPDATE,
  GLOBAL_NGINX_UPDATE,
  GLOBAL_SMTP_MAIL_UPDATE,
  GLOBAL_WP_DEFINES_UPDATE,
  GLOBAL_AUTO_UPDATER_UPDATE,
  GLOBAL_CUSTOM_DEPLOYMENT_UPDATE,
  GLOBAL_WHITE_LABEL_UPDATE,
  WEBSITE_LABEL_GROUPS_UPDATE,
  REPORTS_SETTINGS_UPDATE,
} from './settingActions';
import { cloneDeep } from 'lodash';
import ArrayHelper from 'helpers/array';

const settingReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTING_SET_ALL:
      return action.payload;

    case SETTING_UPDATE:
      const values = ArrayHelper.update(cloneDeep(state), 'name', action.payload);
      return values;

    case SETTING_CLIENT_REPORT_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          client_report_config: action.payload,
        },
      };

    case GLOBAL_NGINX_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          global_nginx_config: action.payload,
        },
      };

    case GLOBAL_SMTP_MAIL_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          global_smtp_mailer_config: action.payload,
        },
      };

    case GLOBAL_AUTO_UPDATER_UPDATE:
      return {
        ...state,
        packages: {
          ...state.packages,
          global_auto_updater_config: action.payload,
        },
      };

    case GLOBAL_WHITE_LABEL_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          global_white_label_config: action.payload,
        },
      };

    case WEBSITE_LABEL_GROUPS_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          website_label_groups: action.payload,
        },
      };

    case GLOBAL_CUSTOM_DEPLOYMENT_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          custom_deployment_config: action.payload,
        },
      };

    case GLOBAL_WP_DEFINES_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          wp_global_defines: action.payload,
        },
      };

    case SETTING_WEBSITES_UPDATE:
      return {
        ...state,
        websites: {
          ...state.websites,
          ...action.payload,
        },
      };
    case SETTING_SERVERS_UPDATE:
      return {
        ...state,
        servers: {
          ...state.servers,
          ...action.payload,
        },
      };

    case SETTING_EMAIL_TEMPLATE_UPDATE:
      return {
        ...state,
        email_templates: ArrayHelper.update(state.email_templates, 'key', action.payload),
      };

    case REPORTS_SETTINGS_UPDATE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          reports_settings: action.payload,
        },
      };

    default:
      return state;
  }
};

export default settingReducer;
