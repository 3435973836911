import React from 'react';
import env from 'config/env';
import JsxHelper from './jsx';

const SERVICE_NAME = 'Google Analytics';

const renderConnectSteps = () =>
  <div>
    <p className='above-header'>
      Follow the steps below to grant {env.getBrandShortName()} access to your Google Analytics account.
      <br />
      For more details, check out the {JsxHelper.createFaqLink('integrating-google-analytics-with-staq', 'Google Analytics Integration Guide')}.
    </p>

    <div className="steps">
      <p>1. Click the <strong>Connect Account</strong> button below.</p>
      <p>2. You'll be redirected to Google to authorize {env.getBrandShortName()} to access your Analytics data.</p>
      <p>3. Check the box that allows {env.getBrandShortName()} to download your Google Analytics data. 
        {JsxHelper.createWarningBox('Without this step, the connection won\'t be completed.', false, 'IMPORTANT', { padding: '5px', margin: '5px 0', })}
      </p>
      <p>4. Once authorized, you'll be redirected back to {env.getBrandShortName()} to finalize the connection.</p>
    </div>
  </div>


const createRedirectUri = (clientID, redirectURI) => {
  const params = {
    client_id: clientID,
    redirect_uri: redirectURI,
    response_type: 'code',
    access_type: 'offline',
    state: 'google-analytics',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/analytics.readonly',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' ')
  };
  const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
  url.search = new URLSearchParams(params).toString();
  return url.toString();
};

const GoogleAnalyticsHelper = {
    renderConnectSteps,
    createRedirectUri,
    SERVICE_NAME,
};

export default GoogleAnalyticsHelper;
