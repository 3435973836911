import { postRequest, deleteRequest, getRequest } from 'config/axiosRequest';
import env from 'config/env';

const listAccounts = (data) => {
  return getRequest({
    url: env.buildApiEndpoint('google-analytics/accounts'),
    data,
  });
}

const connectAccount = (data) => {
  return postRequest({
    url: env.buildApiEndpoint('google-analytics/accounts'),
    data,
  });
}

const disconnectAccount = (data) => {
  return deleteRequest({
    url: env.buildApiEndpoint('google-analytics/accounts/' + data.account_id),
    data,
  });
}

const connectWebsiteProperty = data => {
  return postRequest({
    url: env.buildApiEndpoint('google-analytics/properties'),
    data,
  });
}

const disconnectWebsiteProperty = data => {
  return deleteRequest({
    url: env.buildApiEndpoint('google-analytics/properties/' + data.property_id),
    data,
  });
}

const getWebsiteProperty = data => {
  return getRequest({
    url: env.buildApiEndpoint('google-analytics/properties'),
    data,
  });
}


const GoogleAnalyticsService = {
  listAccounts,
  connectAccount,
  disconnectAccount,
  connectWebsiteProperty,
  disconnectWebsiteProperty,
  getWebsiteProperty,
};

export default GoogleAnalyticsService;
