import React, { useState, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { globalWebsiteLabelGroups } from 'store/setting/settingSelectors';
import { updateGlobalWebsiteLabelGroups } from 'store/setting/settingActions';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import env from 'config/env';
import useModal from 'hooks/useModal';
import useConfirm from 'hooks/useConfirm';

const WebsiteLabelGroups = () => {
  useTitle('Website Label Groups');
  const BREADCRUMBS = JsxHelper.createBreadcrumbs('Website Label Groups', 'settings');
  const globalLabelGroups = useSelector(globalWebsiteLabelGroups);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const modalDialog = useModal();
  const [groups, setGroups] = useState(globalLabelGroups);
  const dispatch = useDispatch();
  const confirm = useConfirm();

  // ---------
  // Lifecycle
  // ---------

  useEffect(() => {
    loading && setLoading(false);
    setGroups(globalLabelGroups.map(group => ({ name: group.name, color: group.color, slug: group.slug, })));
    // eslint-disable-next-line
  }, [globalLabelGroups]);

  // --------
  // Handlers
  // --------

  const onAddNewGroup = () => setModal({ value: '', color: '', slug: null, loading: false })
  const onEditGroup = (group) => setModal({ label: group.name, color: group.color, slug: group.slug, loading: false })
  const onDeleteGroup = (group) => {
    const _groups = [...groups].filter(item => item.slug !== group.slug);
    DialogHelper
      .confirmDelete(confirm, group.name, 'Label group')
      .then(() => {
        // Table loading
        setLoading(true);
        // Save the data
        dispatch(updateGlobalWebsiteLabelGroups({ groups: _groups }))
        .then(() => dispatch(setGlobalSuccessMsg({ model: 'Label group', action: 'deleted' })))
        .finally(() => setLoading(false));
      });
  }

  const saveLabelGroups = () => {
    // Validate
    if (!modal.label || !modal.color) {
      return DialogHelper.error(modalDialog, 'Please fill in all fields');
    }
    // Prepare a copy of the data to be saved
    const _groups = [...groups];
    const group = { name: modal.label, color: modal.color, slug: modal.slug };
    if (group.slug) {
      const index = _groups.findIndex(item => item.slug === group.slug);
      _groups[index] = group;
    } else {
      _groups.push(group);
    }
    // Save
    setModal({ ...modal, loading: true });
    dispatch(updateGlobalWebsiteLabelGroups({ groups: _groups }))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'Label group', action: 'saved' }))
        setModal(false);
      }).catch(() => setModal({ ...modal, loading: false }));
  }

  // ----------
  // Table Data
  // ----------

  const actions = [
    {
      value: 'Edit',
      onClick: onEditGroup,
    },
    {
      value: 'Delete',
      onClick: onDeleteGroup,
    },
  ];

  const headers = [
    JsxHelper.createTableTextHeaderWithCallback('name', 'Group Name', '40%', (group) =>
      <span style={{ color: group.color }}>{group.name}</span>),
    JsxHelper.createTableTextHeaderWithCallback('color', 'Label Color', '35%', (group) =>
      <span style={{ color: group.color }}>{group.color}</span>),
    JsxHelper.createTableActionsHeader(actions, '25%'),
  ];

  // ------
  // Render
  // ------

  return (
    <div className='global-settings'>
      <TitleBar>
        <TitleBar.Title breadcrumbs={BREADCRUMBS}>White Label</TitleBar.Title>
        <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
        {env.getBrandShortName()} allows you categorize website labels under groups with shared attributes.
      </p>
      <Content>
        <div style={{maxWidth: '60%'}}>
          <WPSDataTable
            columns={headers}
            body={groups}
            loading={loading}
            noSearchOnTable={true}
            hidePagination={true}
          />
        </div>
        <div className='action-buttons display-flex-nowrap'>
          {JsxHelper.createButton({ label: 'Add Group', onClick: onAddNewGroup, })}
        </div>
      </Content>
      {modal && DialogHelper.inputs({
        title: 'Website Label',
        icon: modal.slug ? 'edit' : 'create',
        iconColor: 'success',
        btnText: modal.slug ? 'Update' : 'Save',
        onConfirm: saveLabelGroups,
        onClose: () => setModal(false),
        loading: modal.loading,
        inputs: [{
          name: 'label',
          label: 'Group Name',
          placeholder: `Enter a group name`,
          value: modal.label || '',
          onChange: e => setModal({ ...modal, label: e.target.value }),
          required: true,
          minLength: 3,
        },{
          name: 'label',
          type: 'color',
          label: 'Label Color',
          color: modal.color || '',
          onChange: (color) => setModal({ ...modal, color }),
        }]
      })}
    </div>
  );
};

export default WebsiteLabelGroups;
