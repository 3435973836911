import React from 'react';
import {
  FaChartArea,
  FaUsers,
  FaGlobe,
  FaCog,
  FaCogs,
  FaServer,
  FaCopy,
  FaWordpress,
  FaCode,
  FaRegEdit,
  FaFileCsv,
  FaFileImport,
  FaFileExport,
  FaSistrix,
  FaTools,
  FaCheckCircle,
  FaWordpressSimple,
  FaLink,
  FaFileUpload,
  FaPhp,
  FaFileInvoice,
  FaCashRegister,
  FaUser,
} from 'react-icons/fa';
import {
  GrDocumentConfig,
  GrConfigure,
  GrFormPreviousLink,
  GrFormNextLink,
  GrMailOption,
  GrDeploy,
  GrBug,
  GrStripe,
  GrCurrency,
  GrUpdate,
  GrAmex,
  GrDomain,
  GrAnnounce,
} from 'react-icons/gr';
import {
  GiDatabase,
  GiFiles,
  GiSpeedometer,
  GiFirewall,
  GiBarrelLeak
} from 'react-icons/gi';
import {
  GoBrowser,
  GoFileCode,
  GoSearch,
  GoStop
} from 'react-icons/go';
import {
  MdColorLens,
  MdDns,
  MdMail,
  MdLock,
  MdLockOutline,
  MdSettingsBackupRestore,
  MdCloudOff,
  MdCloudDone,
  MdRefresh,
  MdRestore,
  MdBugReport,
  MdSyncProblem,
  MdError,
  MdCached,
  MdSubscriptions,
  MdCheckCircle,
  MdSecurity,
  MdFindReplace,
  MdMonitorHeart,
  MdOutlineFactCheck,
  MdNewReleases,
  MdFilterNone,
  MdOutlineCoronavirus,
  MdTerminal,
  MdOutlinePassword,
  MdSort,
  MdDashboardCustomize,
  MdLabel,
} from 'react-icons/md';
import {
  IoIosGlobe,
  IoMdPlanet,
  IoMdRemoveCircle,
  IoIosCloseCircleOutline,
  IoIosArrowForward,
  IoIosArrowBack,
  IoMdVideocam,
  IoMdList,
  IoMdBookmarks,
  IoIosPaper,
  IoMdOptions,
  IoMdHelp,
  IoMdMail,
  IoIosNotifications,
  IoMdClose,
} from 'react-icons/io';
import {
  FiServer,
  FiSettings,
  FiGitCommit,
  FiActivity,
  FiExternalLink
} from 'react-icons/fi';
import {
  AiOutlineCloudDownload,
  AiFillDatabase,
  AiOutlineDelete,
  AiOutlineInfoCircle,
  AiOutlineHistory,
  AiOutlineBarChart,
  AiFillInfoCircle,
  AiFillCreditCard,
  AiOutlineTable,
  AiFillRightSquare,
  AiOutlineClear,
  AiOutlineGlobal,
} from 'react-icons/ai';
import {
  TiInfoOutline,
  TiUser,
  TiCloudStorage
} from 'react-icons/ti';
import {
  BsBellFill,
  BsFillCalendarFill,
  BsChatDotsFill,
  BsCircleFill,
  BsCommand,
  BsFillPlugFill,
  BsArrowRepeat,
  BsStopwatch,
  BsCardChecklist,
  BsFillHddNetworkFill,
  BsFillPlayCircleFill,
  BsFillPauseCircleFill,
  BsFillStopCircleFill,
  BsGit,
  BsUnlockFill,
  BsLockFill,
} from 'react-icons/bs';
import {
  RiQuestionnaireLine,
  RiLogoutBoxLine,
  RiLoginBoxLine,
  RiFileCopyLine,
  RiRocket2Line,
  RiTodoLine,
  RiCpuLine,
  RiErrorWarningLine,
  RiVisaLine,
  RiMastercardLine,
  RiFileTransferLine,
  RiCheckboxMultipleFill,
  RiChatOffLine,
} from 'react-icons/ri';
import {
  HiOutlineDocumentReport,
  HiOutlineLocationMarker,
  HiTrendingUp,
  HiTrendingDown,
  HiDotsVertical,
} from 'react-icons/hi';
import {
  BiDirections,
  BiRocket,
  BiSpreadsheet,
  BiReceipt,
  BiPulse,
  BiMemoryCard,
  BiCodeAlt,
  BiExpand,
  BiSolidFilePdf,
  BiHide,
} from 'react-icons/bi';
import {
  DiRedis,
  DiGoogleAnalytics,
} from 'react-icons/di';
import {
  SiNginx,
  SiCloudflare,
  SiAmazonec2,
  SiAmazons3,
} from 'react-icons/si';
import {
  VscBellSlash,
  VscDebug,
} from 'react-icons/vsc'

// Custom Icon component
const Icon = ({ tag, count }) => {
  const icons = {
    dashboard: FaChartArea,
    users: FaUsers,
    plans: IoMdPlanet,
    subscriptions: MdSubscriptions,
    regions: IoIosGlobe,
    servers: FaServer,
    websites: GoBrowser,
    themes: MdColorLens,
    plugins: BsFillPlugFill,
    wordpress: FaWordpress,
    dns_zones: MdDns,
    customers: FaUser,
    billing: AiFillCreditCard,
    email: MdMail,
    domain: FaGlobe,
    settings: FaCog,
    multi_settings: FaCogs,
    copy: FaCopy,
    lock: MdLock,
    lockOutline: MdLockOutline,
    restore: MdSettingsBackupRestore,
    cloudOff: MdCloudOff,
    cloudOn: MdCloudDone,
    code: FaCode,
    refresh: MdRefresh,
    backup: MdRestore,
    edit: FaRegEdit,
    create: FaRegEdit,
    remove: IoMdRemoveCircle,
    dns: FiServer,
    download: AiOutlineCloudDownload,
    html: GoFileCode,
    warning: TiInfoOutline,
    closeCircle: IoIosCloseCircleOutline,
    arrow_right: IoIosArrowForward,
    arrow_left: IoIosArrowBack,
    camera: IoMdVideocam,
    bell: BsBellFill,
    fileCsv: FaFileCsv,
    fileImport: FaFileImport,
    fileExport: FaFileExport,
    website_issues: MdBugReport,
    problems: MdSyncProblem,
    list: IoMdList,
    error: MdError,
    danger: MdError,
    unique: IoMdBookmarks,
    incidents: FaSistrix,
    tools: FaTools,
    success: FaCheckCircle,
    database: AiFillDatabase,
    databaseSearch: GoSearch,
    databaseSearchReplace: MdFindReplace,
    databaseTable: AiOutlineTable,
    calendar: BsFillCalendarFill,
    legal: IoIosPaper,
    help: IoMdHelp,
    chat: BsChatDotsFill,
    faq: RiQuestionnaireLine,
    user: TiUser,
    password: MdOutlinePassword,
    login: RiLoginBoxLine,
    logout: RiLogoutBoxLine,
    circle: BsCircleFill,
    cpu: RiCpuLine,
    wordpress_simple: FaWordpressSimple,
    linkicon: FaLink,
    fileupload: FaFileUpload,
    localstorage: GiDatabase,
    cloudstorage: TiCloudStorage,
    uniqueusers: FaUsers,
    createSite: RiRocket2Line,
    migrateSite: RiFileTransferLine,
    websiteConfig: FiSettings,
    cloneSite: RiFileCopyLine,
    stagingSite: MdOutlineFactCheck,
    liveSite: GrDomain,
    devSite: BiCodeAlt,
    commandLine: BsCommand,
    wpOptions: IoMdOptions,
    wpConfig: GrConfigure,
    cronJobs: GrDocumentConfig,
    prevLink: GrFormPreviousLink,
    nextLink: GrFormNextLink,
    trash: AiOutlineDelete,
    info: AiOutlineInfoCircle,
    infoDark: AiFillInfoCircle,
    mailer: GrMailOption,
    emailTemplate: IoMdMail,
    terms: BiSpreadsheet,
    redirect: BiDirections,
    todo: RiTodoLine,
    deployed: GrDeploy,
    commit: FiGitCommit,
    history: AiOutlineHistory,
    filemanager: GiFiles,
    cache: MdCached,
    analytics: AiOutlineBarChart,
    trafficAnalytics: AiOutlineBarChart,
    systemAnalytics: BiPulse,
    cdn: BiRocket,
    activity: FiActivity,
    php: FaPhp,
    bug: GrBug,
    invoice: FaFileInvoice,
    paymentintent: FaCashRegister,
    stripe: GrStripe,
    checkcircle: MdCheckCircle,
    arrowsLoading: BsArrowRepeat,
    ErrorWarningLine: RiErrorWarningLine,
    SQLQueryRunner: AiFillRightSquare,
    currency: GrCurrency,
    stop: GoStop,
    unpaid: BiReceipt,
    clear: AiOutlineClear,
    memory: BiMemoryCard,
    stopwatch: BsStopwatch,
    nginx: SiNginx,
    security: MdSecurity,
    external: FiExternalLink,
    speed: GiSpeedometer,
    checklist: BsCardChecklist,
    firewall: GiFirewall,
    iptables: BsFillHddNetworkFill,
    leaks: GiBarrelLeak,
    git: BsGit,
    redis: DiRedis,
    reports: HiOutlineDocumentReport,
    alertOn: VscBellDotCustom,
    alertOff: VscBellSlash,
    status: MdMonitorHeart,
    stateRunning: BsFillPlayCircleFill,
    statePaused: BsFillPauseCircleFill,
    stateStopped: BsFillStopCircleFill,
    closedLock: BsLockFill,
    openLock: BsUnlockFill,
    globalManage: AiOutlineGlobal,
    localManage: HiOutlineLocationMarker,
    update: GrUpdate,
    trendUp: HiTrendingUp,
    trendDown: HiTrendingDown,
    expand: BiExpand,
    visa: RiVisaLine,
    mastercard: RiMastercardLine,
    amex: GrAmex,
    pdf: BiSolidFilePdf,
    notifications: IoIosNotifications,
    newRelease: MdNewReleases,
    bugFix: VscDebug,
    announce: GrAnnounce,
    duplicate: MdFilterNone,
    hide: BiHide,
    virusSearch: MdOutlineCoronavirus,
    terminal: MdTerminal,
    multipleCheck: RiCheckboxMultipleFill,
    close: IoMdClose,
    verticalDots: HiDotsVertical,
    cloudflare: SiCloudflare,
    sort: MdSort,
    ec2: SiAmazonec2,
    s3: SiAmazons3,
    sendgrid: SendgridCustom,
    offline: RiChatOffLine,
    customize: MdDashboardCustomize,
    googleAnalytics: DiGoogleAnalytics,
    label: MdLabel,
  };
  const TagName = icons[tag];
  return <TagName count={count} />;
};

const SendgridCustom = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="256"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="#9DD6E3"
        d="M256 0v170.667h-85.333v85.33H.002v-85.331H0V85.332h85.333V0z"
      ></path>
      <path fill="#3F72AB" d="M.002 255.996h85.333v-85.333H.002z"></path>
      <path
        fill="#00A9D1"
        d="M170.667 170.667H256V85.331h-85.333zM85.333 85.333h85.334V0H85.333z"
      ></path>
      <path fill="#2191C4" d="M85.333 170.665h85.334V85.331H85.333z"></path>
      <path fill="#3F72AB" d="M170.667 85.333H256V0h-85.333z"></path>
    </svg>
  );
}

const VscBellDotCustom = (props) => {
  const { count = 0 } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.994 7.875A4.008 4.008 0 0112 8h-.01v.217c0 .909.143 1.818.442 2.691l.371 1.113h-9.63v-.012l.37-1.113a8.633 8.633 0 00.443-2.691V6.004c0-.563.12-1.113.347-1.616.227-.514.55-.969.969-1.34.419-.382.91-.67 1.436-.837.538-.18 1.1-.24 1.65-.18l.12.018c.182-.327.41-.625.673-.887a5.15 5.15 0 00-.697-.135c-.694-.072-1.4 0-2.07.227-.67.215-1.28.574-1.794 1.053a4.923 4.923 0 00-1.208 1.675 5.067 5.067 0 00-.431 2.022v2.2a7.61 7.61 0 01-.383 2.37L2 12.343l.479.658h3.505c0 .526.215 1.04.586 1.412.37.37.885.586 1.412.586.526 0 1.04-.215 1.411-.586s.587-.886.587-1.412h3.505l.478-.658-.586-1.77a7.63 7.63 0 01-.383-2.381v-.318zM7.982 14.02a.997.997 0 00.706-.3.939.939 0 00.287-.705H6.977c0 .263.107.514.299.706.191.191.443.299.706.299z"
        stroke="none"
      />
      <circle cx={12} cy={4} r={4.3} stroke="#fff" strokeWidth={1} />
      <text x={12.2} y={5.6} fontSize="5px" textAnchor="middle" fill="#fff">
        {count}
      </text>
    </svg>
  )
}

export default Icon;
