import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import { useHistory } from 'react-router-dom';
import UserHelper from '../../../helpers/user';
import env from 'config/env';

const Websites = () => {
  useTitle('Settings');
  const history = useHistory();

  const wpActions = [
    {
      title: 'WP Config',
      name: 'wp-config',
      desc: 'Updates constants inside the wp-config.php file for all websites globally.',
      icon: 'wpConfig',
      btnText: 'Manage'
    },
    {
      title: 'SMTP',
      name: 'smtp-mailer',
      desc: `Configure SMTP settings for all websites globally.`,
      icon: 'mailer',
      btnText: 'Manage'
    },
    {
      title: 'Nginx Config',
      name: 'nginx-config',
      desc: `Simplify Nginx settings for all websites globally.`,
      icon: 'nginx',
      btnText: 'Manage'
    },
    {
      title: 'Auto Updater',
      name: 'auto-updater',
      desc: `Control the auto-updater settings for all websites globally.`,
      icon: 'update',
      btnText: 'Manage'
    },
    UserHelper.isPartner() ? {
      title: 'Cloudflare',
      name: 'cloudflare',
      desc: 'Manage your Cloudflare integration and settings.',
      icon: 'cloudflare',
      btnText: 'Manage',
    } : null,
    UserHelper.isPartner() ? {
      title: 'SendGrid',
      name: 'sendgrid',
      desc: 'Manage your SendGrid integration and settings.',
      icon: 'sendgrid',
      btnText: 'Manage',
    } : null,
    UserHelper.isPartner() && UserHelper.hasAccessToBetaFeatures() ? {
      title: 'Google Analytics',
      name: 'google-analytics',
      desc: 'Manage your Google Analytics integration and settings.',
      icon: 'googleAnalytics',
      btnText: 'Manage',
    } : null,
    {
      title: 'Deployment',
      name: 'deployment',
      desc: `Get more control over the new website deployment process.`,
      icon: 'deployed',
      btnText: 'Manage'
    },
    UserHelper.isPartner() ? {
      title: 'White Label',
      name: 'white-label',
      desc: `Customize the branding of the hosting plugin for your clients.`,
      icon: 'customize',
      btnText: 'Customize'
    } : null,
    UserHelper.isPartner() ? {
      title: 'Website Label Groups',
      name: 'website-label-groups',
      desc: `Categories website labels under groups with shared attributes.`,
      icon: 'label',
      btnText: 'Manage'
    } : null,
    UserHelper.hasAccessToBetaFeatures() ? {
      title: 'Remote Backup Storage',
      name: 'remote-backup-storage',
      desc: `Configure storage services for ${env.getBrandShortName()} to connect to and store your backups.`,
      icon: 'backup',
      btnText: 'Manage'
    } : null,
  ].filter(Boolean);

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Settings',
      link: UserHelper.isAdmin() ? '/settings/servers' : '',
    },
  ];

  const handleOnClick = e => {
    history.push({
      pathname: `/settings/${e}`,
    });
  };

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Settings</TitleBar.Title>
      </TitleBar>
      <Content>
        {wpActions.map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              btnText={el.btnText}
              icon={el.icon}
              title={el.title}
              onClick={handleOnClick}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default Websites;
