import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg, setGlobalSuccessMsg, setGlobalWarningMsg } from 'store/global/globalActions';
import { updateWebsitePHPConfig, testWebsitePhpVersion } from 'store/website/websiteActions';
import { WPSForm, WPSLabel } from 'styles/layout/forms';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSButton } from 'styles/layout/buttons';
import Tooltip from 'components/layout/tooltip';
import DialogHelper from 'helpers/dialog';
import useModal from 'hooks/useModal';
import WebsiteHelper from 'helpers/website';
import JsxHelper from 'helpers/jsx';
import { getErrorMsg, isString } from 'helpers';
import { isEmptyOrNull } from 'helpers';
import { defaultPhpVersion } from 'store/platform/platformSelectors';
import LocalStorageHelper from 'helpers/localStorage';

const PHPConfig = ({ website }) => {
  const dispatch = useDispatch();
  const config = WebsiteHelper.getPHPConfig(website);
  const modalDialog = useModal();
  const TESTABLE_PHP_VERSION = '8.2';
  const mounted = useRef(true);
  const [phpTestLoading, setPhpTestLoading] = useState(false);
  const [phpTestResult, setPhpTestResult] = useState(false);
  const [keyWarning, setKeyWarning] = useState({});
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    php_version: config.php_version || 'default',
    max_execution_time: config.max_execution_time || 'default',
    upload_max_filesize: config.upload_max_filesize || 'default',
    post_max_size: config.post_max_size || 'default',
    memory_limit: config.memory_limit || 'default',
    max_input_vars: config.max_input_vars || 'default',
    max_input_time: config.max_input_time || 'default',
    pm_max_children: config.pm_max_children || 'default',
    pm_process_idle_timeout: config.pm_process_idle_timeout || 'default',
  });

  // ------------------------------
  // Component Life Cycle
  // ------------------------------

  useEffect(() => {
    if (!phpTestResult) {
      const _phpTestResultCached = getStateFromLocalStorage();
      if (_phpTestResultCached) {
        window.logHelper.info('PHP Test Result found in local storage.', _phpTestResultCached);
        if (_phpTestResultCached.status === 'running') {
          testPhpCompatibility(_phpTestResultCached.job_guid);
        } else {
          setPhpTestResult(_phpTestResultCached);
        }
      }
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // ------------------------------
  // PHP Compatibility Test
  // ------------------------------

  const updatePhpTestResult = (jobGuid, message, status) => {
    status = status || 'running';
    const result = {
      status,
      message,
      color: status === 'running' ? 'primary' : (status === 'success' ? 'success' : 'warning'),
    };
    setPhpTestResult(result);
    saveStateToLocalStorage(jobGuid, result);
  }

  const getStateFromLocalStorage = () =>
    LocalStorageHelper.read(`${website.slug}:phpTestResult`)

  const saveStateToLocalStorage = (jobGuid, testResult) => 
    LocalStorageHelper.write(`${website.slug}:phpTestResult`, {...testResult, job_guid: jobGuid}, 15)

  const testPhpCompatibility = (jobGuid) => {
    const data = {
      website_slug: website.slug,
      php_version: details.php_version,
      job_guid: isString(jobGuid) ? jobGuid : null,
    }
    if (isEmptyOrNull(data.job_guid)) {
      updatePhpTestResult(null, 'Connecting to your website...');
    }
    setPhpTestLoading(true);
    dispatch(testWebsitePhpVersion(data))
      .then((response) => {
        // Show warning returned in response if any
        if (response.warning) {
          dispatch(setGlobalWarningMsg(response.warning));
        }
        // Update the PHP test result
        const statusData = response.status;
        const jobGuid = response.job_guid;
        if (statusData) {
          updatePhpTestResult(jobGuid, statusData.status_message)
        } else {
          updatePhpTestResult(jobGuid, 'Listing PHP files in plugins and themes...');
        }
        // Parse the status data
        if (!statusData || statusData.status === 'running') {
          setTimeout(() => {
            if (!mounted.current) {
              window.logHelper.info('Component is unmounted. Stopping the PHP test.');
              return;
            }
            testPhpCompatibility(jobGuid);
          }, 10000); // Wait 10 seconds and check again
        } else if (statusData.status === 'finished') {
          const badPackages = statusData.results.filter(p => p.error);
          if (isEmptyOrNull(badPackages)) {
            updatePhpTestResult(jobGuid, `Your website is compatible with PHP ${TESTABLE_PHP_VERSION}`, 'success');
          } else {
            updatePhpTestResult(jobGuid, `Found compatibility issues with ${badPackages.map(p => `<b>${p.name}</b>`).join(', ')}.`, 'warning')
          }
        } else if (statusData.status === 'failed') {
          updatePhpTestResult(jobGuid, statusData.status_message, 'error');
        }
      }).catch((error) => {
        const message = getErrorMsg(error);
        updatePhpTestResult(jobGuid, message, 'error');
      }).finally(() => {
        setPhpTestLoading(false);
      });
  };

  // ------------------------------
  // PHP Config
  // ------------------------------

  let phpConfigData = WebsiteHelper.getPhpConfigOptions().map(item => {
    if (item.name === 'php_version') {
      item.default = defaultPhpVersion;
      item.btnOnClick = testPhpCompatibility;
      item.btnText = phpTestResult && phpTestResult.status === 'error' ? 'Try Again' : 'Test PHP version compatibility';
      item.btnHide = () => details.php_version !== TESTABLE_PHP_VERSION || phpTestLoading || (phpTestResult && phpTestResult.status !== 'error');
    }
    return item;
  });

  for (const i in phpConfigData) {
    const configName = phpConfigData[i].name;
    // Add name field to options
    phpConfigData[i].options = phpConfigData[i].options.map(o => ({...o, name: configName}));
    // Set selected value
    const userValue = details[configName];
    const selectedOption = phpConfigData[i].options.find(o => o.value === userValue)
    phpConfigData[i].value = {
      value: selectedOption ? selectedOption.value : phpConfigData[i].options[0].value,
      label: selectedOption ? selectedOption.label : phpConfigData[i].options[0].label
    }
  }

  const validateDataBeforeSubmit = (data) => {
    const uploadMax = parseInt(data.upload_max_filesize);
    const postMax = parseInt(data.post_max_size);
    if ((data.post_max_size === 'default' && uploadMax > 25) || (postMax < uploadMax)) {
      dispatch(setGlobalWarningMsg('Max Post Size cannot be less than Max File Upload.'))
      return false;
    }
    return true;
  }

  const maybeDisplayExtraChargeWarning = (key, value) => {
    if (!['memory_limit', 'pm_max_children'].includes(key)) {
      return false;
    } else if (keyWarning[key]) {
      return false; // already displayed
    } else if (value === 'default' || !value) {
      return false; // no need to display
    }

    const phpConfig = phpConfigData.find(c => c.name === key);
    if (!phpConfig || parseInt(phpConfig.default) >= parseInt(value)) {
      return false; // no need to display
    }

    const link = JsxHelper.convertToString(JsxHelper.createPlansLink());
    DialogHelper.warning(
      modalDialog,
      `Changing the <b>${phpConfig.title}</b> option can incur extra charges, depending on the plan you are on. For more information, please visit our ${link} page.`,
    );
    setKeyWarning(prev => ({ ...prev, [key]: true }));
  }

  const onSubmit = () => {
    const data = {
      website_slug: website.slug,
      ...details,
    };
    if (!validateDataBeforeSubmit(data)) {
      return;
    }
    setLoading(true);
    dispatch(updateWebsitePHPConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'PHP Config', onId: WebsiteHelper.getLabel(website), action: 'updated' })))
      .finally(() => setLoading(false));
  };

  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'php_version' && phpTestLoading) {
      dispatch(setGlobalErrorMsg('Please wait for the PHP version compatibility test to finish.'));
      return;
    }
    setDetails(prev => ({ ...prev, [name]: value }));
    maybeDisplayExtraChargeWarning(name, value);
  };

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title> PHP Config</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader padding-left-0'>
      Enhance the capabilities of your WordPress website by controlling advanced PHP runtime configurations directly from the server level.
      </p>
      <div style={{ maxWidth: '600px', marginTop: '20px' }}>
        {
          phpConfigData.filter(item => !item.hide).map((item, index) => {
            return (
              <WPSForm.RowItem key={index} margin='0px'>
                <div className='display-inline-block'>
                  <WPSLabel className='display-inline-block'>{item.title}</WPSLabel>
                  <Tooltip place='right' text={item.desc} />
                </div>
                {JsxHelper.createSelectInput({
                  name: item.name,
                  value: item.value ? item.value.value : null,
                  options: item.options,
                  onChange,
                  class: 'secondary-box-select',
                  sortOff: true,
                })}
                {item.name === 'php_version' && phpTestResult && (
                  <div className='php-test-result'>{JsxHelper.createResultBox(phpTestResult.status, phpTestResult.message, {marginBottom: '0'})}</div>
                )}
                {item.btnOnClick && !item.btnHide() && (
                  <WPSButton onClick={item.btnOnClick} className='text--btn'>{item.btnText}</WPSButton>
                )}
              </WPSForm.RowItem>
            );
          })
        }
      </div>
      <WPSButton className='save--btn' loading={loading} onClick={onSubmit} style={{marginBottom: '25px'}}>
        Save
      </WPSButton>
    </Container>
  );
};

export default PHPConfig;
