import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Container } from 'styles/website/profile';
import { WPSForm } from 'styles/layout/forms';
import { useDispatch } from 'react-redux';
import { updateWebsiteDomains } from 'store/website/websiteActions';
import { TitleBar } from 'styles/layout/titlebar';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';
import ArrayHelper from 'helpers/array';
import JsxHelper from 'helpers/jsx';
import DomainHelper from 'helpers/domain';
import { setGlobalSuccessMsg, setGlobalErrorMsg, setResourceIsBusyMsg } from 'store/global/globalActions';
import { isWebsiteBusy, isEmpty, isEmptyOrNull } from 'helpers';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import WebsiteService from 'services/website';

const DnsManager = ({
  website,
  domains,
  onDefaultDomainChange,
  onDomainsChange,
  nextButtonHandler,
  nextButtonText,
  onSaveCompletedHandler,
  hideSaveButton,
  hideNextButton
}) => {
  useTitle('Website Domains');
  const dispatch = useDispatch();
  const defaultDomain = website.default_domain;
  const cloudflareZone = WebsiteHelper.getCloudflareZone(website);
  const mounted = useRef(true);
  const { register, errors, handleSubmit, reset } = useForm({ reValidateMode: 'onAddNewDomain' });
  const [websiteDomains, setWebsiteDomains] = useState([]);
  const [resolvedDomains, setResolvedDomains] = useState({});
  const [userInput, setUserInput] = useState({
    domain: '',
    delay_propagation: !isEmptyOrNull(cloudflareZone),
  });
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [disableNextBtn, setDisableNextBtn] = useState(!website.is_live);
  const [addDomainLoading, setAddDomainLoading] = useState(false);
  const [modal, setModal] = useState(false);
  hideSaveButton = hideSaveButton || false;
  hideNextButton = hideNextButton || false;

  // ------------------------------
  // LIFECYCLE
  // ------------------------------

  useEffect(() => {
    initWebsiteDomains();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, [domains]);

  // ------------------------------
  // INITIALIZERS
  // ------------------------------

  const initWebsiteDomains = (_domains) => {
    _domains = ArrayHelper.uniqueDeep(_domains || domains, 'domain');
    setWebsiteDomains(_domains);
    resolveDns(_domains.map(d => d.domain));
  }

  // ------------------------------
  // DNS RESOLVER
  // ------------------------------

  const resolveDns = async (_domains, forceResolve) => {
    // If no domains, do not resolve.
    if (isEmptyOrNull(_domains)) {
      return;
    }
    // If force, include all domains.
    let _resolvedDomains = resolvedDomains;
    let toResolve = _domains.filter(d => !_resolvedDomains[d]);
    if (forceResolve) {
      setResolvedDomains({});
      _resolvedDomains = {};
      toResolve = _domains;
    }
    // Resolve the domains.
    if (!isEmpty(toResolve)) {
      setApiLoading(true);
      WebsiteService.testDomains({ website_slug: website.slug, domains: toResolve })
        .then((data) => {
          window.logHelper.info('Resolved domains:', data);
          setResolvedDomains({ ..._resolvedDomains, ...data });
        })
        .catch((error) => dispatch(setGlobalErrorMsg(error)))
        .finally(() => setApiLoading(false));
    }
  };

  // ------------------------------
  // BUTTON HANDLERS
  // ------------------------------

  const saveDomains = () => {
    // If the website is busy, do not allow the user to save the domains.
    if (isWebsiteBusy(website)) {
      dispatch(setResourceIsBusyMsg({ id: WebsiteHelper.getLabel(website), model: 'website' }));
      return;
    }
    // Save the domains.
    const data = {
      website_slug: website.slug,
      domains: websiteDomains.map(domain => domain.domain),
      default_domain: ArrayHelper.find(websiteDomains, 'is_default', true).domain,
      delay_propagation: userInput.delay_propagation,
    };
    setApiLoading(true);
    dispatch(updateWebsiteDomains(data))
      .then((_website) => {
        onDefaultDomainChange && onDefaultDomainChange(data.default_domain, defaultDomain);
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'domain', action: 'updated' }));
        setDisableNextBtn(false);
        onSaveCompletedHandler(_website);
      })
      .catch(error => onSaveCompletedHandler(null, error))
      .finally(() => setApiLoading(false));
  };

  const onRefreshDomainDns = async () => {
    setRefreshLoading(true);
    await resolveDns(websiteDomains.map(d => d.domain), true);
    setRefreshLoading(false);
  }

  const onAddNewDomain = async formData => {
    const domain = formData.domain;
    // Set loading state and disable the next button.
    setAddDomainLoading(true);
    setDisableNextBtn(true);
    // Add the new domain.
    const data = { domain, is_default: isEmpty(websiteDomains) };
    initWebsiteDomains([...websiteDomains, data]);
    // Reset loading state and close the modal.
    onDomainsChange && onDomainsChange();
    setTableLoading(false);
    setAddDomainLoading(false);
    setModal(false);
  };

  const onChange = e => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    setUserInput(prev => ({ ...prev, [name]: value }));
  };

  const handleSetAsPrimary = e => {
    if (e.domain) {
      const userInputs = websiteDomains.map(d => ({ ...d, is_default: d.domain === e.domain }));
      initWebsiteDomains(userInputs);
    }
  };

  // ------------------------------
  // TABLE
  // ------------------------------

  const actions = [
    {
      value: 'Remove',
      doHide: item => item.is_default,
      onClick: item => {
        const userInputs = websiteDomains.filter(d => d.domain !== item.domain);
        initWebsiteDomains(userInputs);
        onDomainsChange && onDomainsChange();
      },
    },
  ];

  const headers = [
    JsxHelper.createTableTextHeader('domain', 'Domain', '45%'),
    JsxHelper.createTableTextHeaderWithCallback('domain', 'Points To', '30%', row => {
      const status = resolvedDomains[row.domain] || false; // false = loading
      return DomainHelper.renderDnsManagerPointsTo(status);
    }),
    JsxHelper.createTableTextHeaderWithCallback('is_default', 'Default', '15%', (row) => {
      return DomainHelper.renderDnsManagerPrimaryButtonCell(row, handleSetAsPrimary);
    }),
    JsxHelper.createTableActionsHeader(actions, '10%'),
  ];

  return (
    <Container id='dns-manager'>
      <TitleBar className='titlebar' style={{ minHeight: '0px' }}>
        <TitleBar.Title>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Fragment>
              Add your domains below and point them to:
              {JsxHelper.createCopyButton({ value: website.public_ipv4, dispatch })}
            </Fragment>
          </span>
        </TitleBar.Title>
      </TitleBar>
      <Content>
        <WPSDataTable columns={headers} body={websiteDomains} loading={tableLoading} dataKey='domain' noSearchOnTable={true} />
        <WPSForm.RowItem direction='row' style={{minHeight: '0px', marginBottom: '24px'}}>
          {JsxHelper.createCheckbox({
            label: 'Delay propagation',
            name: 'delay_propagation',
            onChange,
            checked: userInput.delay_propagation,
            disabled: apiLoading,
          })}
        </WPSForm.RowItem>
        <div className='display-flex action-buttons margin-bottom-30'>
          {JsxHelper.createButton({
            label: 'Add Domain',
            classes: 'info--btn',
            onClick: () => {
              reset();
              setModal(true);
            },
            disabled: apiLoading,
          })}
          {JsxHelper.createButton({
            label: 'Refresh DNS',
            onClick: onRefreshDomainDns,
            loading: refreshLoading,
            disabled: apiLoading,
          })}
          {!hideSaveButton && JsxHelper.createButton({
            label: 'Save',
            onClick: saveDomains,
            loading: apiLoading,
            disabled: apiLoading,
          })}
          {!hideNextButton && JsxHelper.createButton({
            label: nextButtonText || 'Next',
            onClick: nextButtonHandler,
            disabled: apiLoading || disableNextBtn,
          })}
        </div>
        {modal && DialogHelper.inputs({
          title: 'Add New Domain',
          loading: addDomainLoading,
          onClose: () => setModal(false),
          onConfirm: handleSubmit(onAddNewDomain),
          confirmBtn: 'Add',
          inputs: [{
            label: 'Domain name',
            name: 'domain',
            value: userInput.domain,
            placeholder: `Example: ${website.is_live ? `www.${website.registrable_domain}` : 'mydomain.com'}`,
            onChange,
            ref: register({
              required: FormHelper.messages.required,
              pattern: {
                value: FormHelper.regex.domain,
                message: FormHelper.messages.invalidDomain,
              },
            }),
            errors,
          }],
        })}
      </Content>
    </Container>
  );
};

export default DnsManager;
