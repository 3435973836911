import React from 'react';
import env from 'config/env';
import JsxHelper from './jsx';
import DialogHelper from './dialog';

const SENDGRID_APP_URL = 'https://app.sendgrid.com';

const renderConnectSteps = () =>
  <div>
    <p className='above-header'>
    Follow the steps below to connect your SendGrid account to the {env.getBrandShortName()} dashboard.<br />
    For more information see the {JsxHelper.createFaqLink('integrating-sendgrid-with-staq', 'SendGrid Integration Guide')}.
    </p>
    <div className='steps'>
    <p>1. From the {JsxHelper.createLink(SENDGRID_APP_URL, 'SendGrid dashboard')}, go to <b>Settings</b> {JsxHelper.ARROW_SYMBOL} <b>API Keys</b>.</p>
    <p>2. Click on <b>Create API Key</b>.</p>
    <p>3. Select <b>Full Access</b> under <b>API Key Permissions</b>.</p>
    <p>4. Give the API key a name (e.g. {env.getBrandShortName()}-API).</p>
    <p>5. Click on <b>Create & View</b>.</p>
    <p>6. Copy the API key and paste it in the field below.</p>
    </div>
  </div>

const _renderDNSRecordRow = (dispatch, record, key) => {
  const { valid, type, host, data } = record;
  const _color = valid ? 'success' : 'danger';
  return <tr key={key}>
    <td>{JsxHelper.createIcon({
      icon: _color,
      color: _color,
      style: {marginLeft: '30%'},
      tooltip: valid ? 'Verified' : 'Failed, please set up the DNS records and verify again.',
    })}</td>
    <td>{type.toUpperCase()}</td>
    <td>{JsxHelper.createCopyButton({ dispatch, value: host, background: 'primary' })}</td>
    <td>{JsxHelper.createCopyButton({ dispatch, value: data, background: 'info' })}</td>
  </tr>
}

const _renderDNSRecordsTable = (dispatch, records) => 
  <table className='table' style={{marginBottom: '24px'}}>
    <thead>
      <tr>
        <th>STATUS</th>
        <th>TYPE</th>
        <th>HOST</th>
        <th>VALUE</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(records).map((key) => _renderDNSRecordRow(dispatch, records[key], key))}
    </tbody>
  </table>

const openDNSRecordsModal = (modalDialog, dispatch, verifyAPI, authDomain) =>
  DialogHelper.html(modalDialog, _renderDNSRecordsTable(dispatch, authDomain.dns), {
    customClass: 'large-dynamic-modal',
    btnText: 'Verify DNS Records',
    btnsStyle: 'standard',
    type: 'warning',
    btnOnClick: (closeBtn) => {
      verifyAPI(authDomain);
      closeBtn();
    }
  });
  
const SendGridHelper = {
    renderConnectSteps,
    openDNSRecordsModal,
    SENDGRID_APP_URL,
};

export default SendGridHelper;
