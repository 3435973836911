import styled, { createGlobalStyle } from 'styled-components';
import theme from './theme';

const SUCCESS_COLOR = theme.color.success;
const DANGER_COLOR = theme.color.danger;
const WARNING_COLOR = theme.color.warning;
const PRIMARY_COLOR = theme.color.primary;
const INACTIVE_COLOR = theme.color.inactive;
const DISABLED_COLOR = theme.color.disabled;
const INFO_COLOR = theme.color.info;
const BLACK_COLOR = theme.color.black;
const WHITE_COLOR = theme.color.white;
const BODY_COLOR = theme.color.body;
const LINK_COLOR = theme.color.link;
const ALT_COLOR = theme.color.alternative;
const BODY_BACKGROUND = theme.background.body;
const ALT_BACKGROUND = theme.background.alternative;
const SUCCESS_BACKGROUND = theme.background.success;
const PRIMARY_BACKGROUND = theme.background.primary;
const WARNING_BACKGROUND = theme.background.warning;
const DANGER_BACKGROUND = theme.background.danger;
const INFO_BACKGROUND = theme.background.info;
const EXAMPLE_BACKGROUND = theme.background.example;
const BOX_BACKGROUND = theme.background.box;

// Macros
const THIN_BUTTON = `
  width: 140px;
  max-width: 140px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 20px;
  background: ${WHITE_COLOR};
  background-clip: padding-box;
  padding: 7px 0px !important;
  color: ${BLACK_COLOR};
`
const ROUNDED_BUTTON = `
  width: 140px;
  max-width: 140px;
  display: block !important;
  border-radius: 100rem !important;
  padding: 7px 0px !important;
  border: solid 2px transparent;
`
const ROUNDED_LIGHT_BUTTON = `
  ${ROUNDED_BUTTON}
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 2px 1000px 1px #fff inset;
  color: ${BLACK_COLOR} !important;
`;
const ROUNDED_DARK_BUTTON = `
  ${ROUNDED_BUTTON}
  color: ${WHITE_COLOR} !important;
`
const ROUNDED_LIGHT_BUTTON_AFTER = `
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: '';
  z-index: -1;
  border-radius: 22px;
`;

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 14px;
    height: 100vh;
  }
  body {
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    height: 100%;
    background: ${BODY_BACKGROUND};
  }
  #root {
    height: 100%;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }
  legend {
    font-weight: bold;
  }
  h1 {
    margin: 0;
  }
  a, button, input[type=submit] {
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    outline: none;
    color: inherit;
  }
  button {
    border: none;
    background: none;
  }
  input, select {
    font-family: inherit;
    font-size: inherit;
    outline: none;
    color: inherit;
  }
  .container {
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  a {
    text-decoration: none;
  }
  i {
    font-style: normal;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: inherit;
    -webkit-text-fill-color: inherit;
    background: inherit;
  }
  hr {
    border: none;
    background: #A8B5D9;
    height: 1px;
  }
  .custom-scroll {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.border.scrollBar};
      border-radius: 5px;
    }
  }
  .custom-search-box-position{
      position: sticky;
      left: 0;
  }
  .custom-select {
    min-width: 180px;
    height: 100%;
    background: ${WHITE_COLOR};
    padding: 7px;
    color: inherit;
    border: 1px solid ${props => props.theme.border.primary};
    border-radius: ${props => props.theme.border.radius};
  }
  .read-more-btn {
    color: ${({ theme }) => theme.color.link};
    padding-left: 8px;
  }
  /* Sketch picker */
  .sketch-picker-no-rgb .sketch-picker .flexbox-fix:nth-child(4) {
    display: none !important;
  }
  .sketch-picker-no-rgb .sketch-picker .flexbox-fix > div:not(:first-child) {
    display: none !important;
  }
  .sketch-picker-no-rgb .sketch-picker > div:nth-child(3) >div > div {
    text-align: center !important;
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
  }
  .sketch-picker-no-rgb .sketch-picker > div:nth-child(3) input {
    text-align: center;
  }
  .sketch-picker-no-rgb .sketch-picker > div:nth-child(3) label {
    display: none !important;
  }
  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid ${props => props.theme.border.primary};
    -webkit-text-fill-color: auto;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .websites-table {
    td:nth-child(1), td:nth-child(2), td:nth-child(3){
      position: sticky;
      background-color: ${props => props.theme.table.oddRows};
    }
    td:nth-child(1){
      left: 0;
    }
    td:nth-child(2){
      left: 120px;
    }
    td:nth-child(3){
      left: 220px;
    }
  }
  p, span {
    line-height: 1.3em;
  }
  p.subheader {
    width: 55%;
    padding: 0 24px;
    font-size: 15px;
    text-align: left;
  }
  p.subheader-box {
    margin-top: 24px;
    width: 100% !important;
  }
  p .subheader-link, .box-link {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
  .input-tip .custom-tip svg {
    font-size: 13px;
    padding-top: 3px;
    margin-left: -5px;
  }
  .id-field-container > div > span {
    margin: 17px 0 0 10px;
  }
  .input-comment-text {
    margin-top: 7px;
    font-style: italic;
    font-size: 13px;
    color: ${INACTIVE_COLOR};
    display: block;
  }
  /*------- Notification Popup -------*/
  .stq-pp ul {
    text-align: left;
    margin-top: 20px;
  }
  .stq-pp ul li {
    list-style: disc;
    margin-top: 10px;
  }
  .stq-pp h2 {
    margin-bottom: 5px;
  }
  .stq-pp-logo img {
    width: 100px;
    height: auto;
    display: block;
    margin: auto auto 15px auto;
  }
  .stq-pp-button {
    background: ${INFO_BACKGROUND};
    color: ${WHITE_COLOR};
    padding: 9px 16px;
    border-radius: 5px;
    margin-top: 25px;
  }
  /*------- Colors Classes ----------------------*/
  .color-primary{
    color: ${PRIMARY_COLOR};
  }
  .class-link{
    color: ${LINK_COLOR} !important;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    outline: none;
  }
  .goto-link {
    cursor: pointer;
    color: ${PRIMARY_COLOR};
    text-decoration: underline;
  }
  .goto-link-prefix {
    color: ${BODY_COLOR};
  }
  .warning svg {
    color: ${WARNING_COLOR} !important;
  }
  .success svg {
    color: ${SUCCESS_COLOR} !important;
  }
  .primary svg {
    color: ${PRIMARY_COLOR} !important;
  }
  .danger svg {
    color: ${DANGER_COLOR} !important;
  }
  /*------- Info Box ----------------------*/
  .info-box {
    padding: 20px;
    line-height: 1.6;
    background: ${BOX_BACKGROUND};
    border-radius: 8px;
    border: 1px solid #ddd;
    max-width: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  .info-box:not(:first-child) {
    margin-top: 35px;
  }
  .info-box h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: ${PRIMARY_COLOR};
  }
  .info-box p {
    font-size: 14px;
    color: ${BODY_COLOR};
    margin-bottom: 8px;
  }
  .info-box strong {
    font-weight: bold;
    color: ${BLACK_COLOR};
  }
  /*------- Data Table (v2) -----------*/
  .break-all {
    word-break: break-all !important;
  }
  .cell-header {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: ${BODY_COLOR};
    text-decoration: inherit;
  }
  .cell-subheader {
    font-size: 11.5px !important;
    display: block;
    word-break: keep-all;
    text-decoration: inherit;
  }
  .from-to-cell-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
  }
  .rdt_TableRow > .rdt_TableCell: nth-child(1) {
    margin: 0 -20px 0 20px;
  }
  .rdt_TableCol:first-child > div {
    padding-left: 20px !important;
  }
  .rdt_TableCell {
    display: flex;
  }
  .wps-bubble-small,
  .wps-button-small {
    font-size: 12px;
    padding: 3px 5px;
  }
  .wps-button-small {
    min-height: 24px;
  }
  .wps-icon {
    font-size: 16px;
    display: flex;
  }
  .wps-icon-small {
    font-size: 12px;
  }
  .instruct-modal {
    min-width: 750px !important;
  }
  .instruct-modal .input-modal-header {
    background: #f2f2f2;
  }
  .instruct-modal .input-modal-header .above-header {
    background: ${BODY_BACKGROUND};
    padding: 10px 0 15px 10px;
    line-height: 1.5em;
  }
  .instruct-modal .input-modal-header .steps {
    margin: 10px 0 35px 10px;
    padding: 5px;
    font-size: 15px;
  }
  .instruct-modal .input-modal-header .steps p {
    line-height: 30px !important;
  }
  .instruct-modal 
  .input-modal {
    min-width: 500px;
    max-width: 570px;
  }
  .input-modal .modal-title {
    text-align: left;
    display: inline-block;
  }
  .input-modal .wps-icon {
    font-size: 25px;
    margin-right: 8px;
  }
  .input-modal-header {
    font-size: 14px;
    margin: 10px 0 20px 0;
  }
  .input-modal-label {
    margin-bottom: 5px;
  }
  .input-modal-after {
    margin-top: 6px;
  }
  .input-modal .modal-custom-style {
    padding: 2px;
  }
  .input-modal input {
    font-size: 13px;
    width: 100%;
  }
  .input-modal input[type=checkbox]:not(.fix-input-modal-toggle) {
    width: 20px !important;
  }
  .input-modal .wps-icon svg {
    width: 1em;
    height: 1em;
  }
  .fix-input-modal-toggle {
    width: 36px !important;
  }
  .confirm-action-info {
    margin-bottom: 15px;
  }
  .list-style-none {
    list-style: none !important;
    list-style-type: none !important;
  }
  li.list-item {
    font-weight: normal !important;
    padding: 2px !important;
  }
  li.list-item > span {
    margin-left: -5px;
    width: fit-content !important;
  }
  .select-container > div,
  input[id=search] {
    border-color: ${props => props.theme.border.primary};
  }
  .select-container div[class*='indicatorContainer'] > svg {
    color: ${props => props.theme.border.primary};
  }
  .select-container span[class*='indicatorSeparator'] {
    background-color: ${props => props.theme.border.primary};
  }
  .action-buttons button {
    margin-right: 6px;
    margin-top: 20px;
  }
  .inline-action-buttons {
    display: inline-block;
  }
  .inline-action-buttons button {
    display: inline-block;
    margin-right: 6px;
    margin-top: 20px;
  }
  .text-align-center {
    text-align: center;
  }
  .text-align-left {
    text-align: left;
  }
  .text-align-right {
    text-align: right;
  }
  /*------- Others ----------------------*/
  .stripe-info-item {
    padding-left: 12px;
    text-align: left;
  }
  .stripe-info-item:nth-child(1) {
    margin-bottom: 3px;
  }
  .stripe-info-item > span:nth-child(1) {
    min-width: 75px;
  }
  .stripe-info-item > span:nth-child(2) {
    word-break: break-all;
    text-align: left;
    padding-right: 12px;
  }
  /*------- Dashboard Cards ----------------*/
  .dashboard-cards .card-title {
    text-align: left !important;
    font-weight: 900 !important;
    font-size: 19px !important;
    color: ${BODY_COLOR} !important;
    margin-bottom: 8px !important;
  }
  .progress-table .progress-label {
    font-size: 13px;
    font-weight: bold;
    color: ${PRIMARY_COLOR}
  }
  .notification-label {
    font-size: 13px;
    font-weight: bold;
    color: ${PRIMARY_COLOR}
  }
  /*------- Package Cards ------------------*/
  #connect-git-wizard .file-data-info > .package-metadata > li {
      padding: 0;
      margin-bottom: 0;
  }
  #connect-git-wizard .package-card-title {
      margin-bottom: 20px;
  }
  .file-data-info {
      padding: 25px 40px
  }
  .file-data-info > .package-metadata > li > h4{
      padding: 0 0 0 0;
      min-width: 130px;
  }
  .package-card-title {
    display: inline-flex;
    margin-bottom: 30px;
  }
  .package-card-title>svg {
    width: 50px;
    height: 40px;
    color: ${INFO_COLOR};
  }
  .package-card-title>span {
      padding: 14px 18px;
      font-weight: bold;
      font-size: 18px;
      color: ${INFO_COLOR};
  }
  /*------- Advanced Tabs ------------------*/
  .elements-box {
    display: inline-block;
    width: 30%;
    max-width: 300px;
    margin: 6px 15px 25px 15px;
    padding: 26px 0 0 0;
    border: 2px solid #e0e9ff;
    border-radius: 5px;
    box-shadow: none;
    vertical-align: top;
    min-height: 240px;
  }
  .elements-box > div {
    text-align: center;
    font-weight: bold;
  }
  .box-icon > svg {
    width: 100%;
    height: 100%;
    max-width: 40px;
    margin-bottom: 10px;
    color: #398eff;
  }
  .box-icon > svg path {
    stroke: #398eff !important;
  }
  .selection-box-btn {
    display: block !important;
    margin: 12% auto 5%;
    width: 46%;
    border-radius: 100rem !important;
    padding: 7px 20px !important;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #7083e2, #4fcceb) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    box-shadow: 2px 1000px 1px #fff inset;
    color: ${BODY_COLOR} !important;
  }
  .selection-box-btn:disabled,
  .selection-box-second-btn:disabled {
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, rgba(225, 225, 225, 1), rgba(225, 225, 225, 1)) !important;
    box-shadow: 2px 1000px 1px rgba(225, 225, 225, 1) inset !important;
  }
  .selection-box-second-btn {
    display: flex !important;
    width: 46%;
    margin: 12% auto 5%;
    border-radius: 100rem !important;
    padding: 7px 20px !important;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #ff0000, #ff8a5c) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    box-shadow: 2px 1000px 1px #fff inset;
    color: ${BODY_COLOR} !important;
  }
  .secondBtnCustom {
    padding: 0px !important;
    width: 49%;
  }
  .select-parent {
    margin: 30px 0 0 0;
  }
  .select-parent > div {
    font-weight: 500;
    color: #333333;
  }
  .elements-box .description {
    min-height: 40px;
  }
  .elements-box .description > div {
    margin: 15px 8px 0px 8px;
    font-weight: 400;
    color: #333333;
  }
  .elements-box .custom-tip {
    margin: 0px;
  }
  .elements-box .custom-tip svg {
    height: 0.9em;
  }
  .box-title {
    color: #3e4dbb !important;
    font-weight: 100 !important;
  }
  .status-left .status-dropdown {
    color: ${PRIMARY_COLOR} !important;
    background: ${WHITE_COLOR} !important;
  }
  .status-left .status-dropdown .remove {
    color: ${DANGER_COLOR} !important;
  }
  .status-advanced-tab {
    height: 100%;
    padding: 0;
    margin: 15px;
  }
  .status-advanced-tab .loadingspinner {
    display: inline-block;
    margin-bottom: 7px;
  }
  .status-advanced-tab .loading-status-text {
    font-weight: normal;
    display: inline-block;
    width: 100%;
    padding: 6px;
    margin-top: 15px;
    background: #eaeaea;
  }
  .status-advanced-tab .loading-wait-text {
    font-size: 13px;
    display: block;
    margin-top: 3px;
  }
  .status-advanced-tab .status-data {
    margin-top: 5px;
  }
  .status-advanced-tab .status-icon {
    font-size: 25px;
    margin-right: 0px;
  }
  .status-advanced-tab .status-text {
    vertical-align: super;
    font-weight: 400;
  }
  .status-advanced-tab .status-btn {
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .status-advanced-tab .status-btn div[color] {
    margin-right: 20px !important;
  }
  .status-advanced-tab .status-icon svg {
    margin-right: 0px;
  }
  .status-advanced-tab .__react_component_tooltip {
    font-weight: normal;
    text-align: left;
  }
  .__react_component_tooltip.place-top::before {
    display: none;
  }
  .cdn-proxy-box .selection-box-btn:disabled {
    width: 55%;
  }
  .cdn-cache-box, .cdn-proxy-box, .cdn-settings-box, .cdn-cloudflare-box {
    min-height: 300px;
  }
  #new-website-options {
    display: flex;
    flex-wrap: wrap;
    margin: 50px;
    min-height: 300px;
  }
  .btn-disabled-tooltip-fix:disabled {
    color: #575757 !important;
    background: linear-gradient(90deg,rgba(202,202,202,1) 0%,rgba(225,225,225,1) 100%) !important;
    cursor: not-allowed;
    pointer-events: all !important;
  }
  .btn-disabled-tooltip {
    font-weight: 400;
  }
  /* Invoice */
  .invoice-breakdown .elements-box {
    min-height: 280px;
  }
  .subscription-breakdown-row {
    font-size: 13px;
    margin-left: 2px;
    text-align: left;
    margin-top: 6px;
  }
  .subscription-breakdown-row .excess {
    font-style: italic;
    font-size: 11.5px;
    margin-top: 3px;
    color: ${WARNING_COLOR};
  }
  .subscription-breakdown-row .prorate {
    color: #fc8121 !important;
  }
  .subscription-breakdown-row .prorate.prorate-notice {
    margin-top: 3px;
    font-size: 10px;
    font-style: italic;
  }
  /*------- Analytics ----------------------*/
  .analytics-filters{
    display:inline-flex;
    margin: 0 0 15px 0;
  }
  .analytics-filters button{
    min-width: 85px;
  }
  .analytics-tables{
    width: 100%;
    display: inline-block;
  }
  .analytics-tables .stats-tabs{
    min-width: 500px;
    display: inline-block;
  }
  .analytics-tables .traffic-tabs{
    width: 45%;
  }
  .analytics-tables .system-tabs{
    width: 100%;
  }
  .analytics-tables .stats-tabs:first-child{
    margin: 0px 20px 30px 0px;
  }
  .stats-tabs > ul{
    width: 1px !important;
  }
  .stats-tabs li {
    white-space: nowrap;
  }
  /*------- Charts Wrapper ----------------------*/
  .analytics-tables .recharts-wrapper{
    width:100% !important;
  }
  .analytics-tables .recharts-wrapper > .recharts-surface,.recharts-legend-wrapper,.recharts-tooltip-wrapper{
    width:100% !important;
  }
  .recharts-wrapper .recharts-tooltip-wrapper{
    max-width: 350px;
  }
  /*------- TinyMCE Editor ----------------------*/
  .TinyMCE-tags .custom-scroll > form > section > div > div > div > div:nth-of-type(2) {
    flex-wrap: wrap;
  }
  .TinyMCE-tags .custom-scroll > form > section > div > div > div > div:nth-of-type(2) > div {
    margin-bottom: 5px !important;
  }
  /*------- Steppers Styles ---------------------*/
  .create-site-stepper{
    width: 100%;
    max-width: 700px;
    position: absolute;
    left: -28px;
  }
  .step-subheader {
    width: 70%;
    margin-bottom: 30px;
    font-size: 15px;
    text-align: justify;
    color: ${PRIMARY_COLOR}; /* color-primary */
  }
  /*------- Websites ---------------------*/
  .dns-points-to {
    margin-left: 8px;
    position: relative;
    top: 2.5px;
    font-size: 17px;
  }
  .dns-auto-config svg {
    position: relative;
    top: 3px;
    margin-left: 4px;
  }
  .custom-form-row-item {
    display: inline-flex;
    width: 45%;
    max-width: 500px;
    margin: 0 15px 0 0;
    min-height: 70px;
  }
  .display-block{
    display: block;
  }
  .KIWI-option .live .ws-dropdown-sign, .KIWI-option .create .ws-dropdown-sign:hover {
    color: ${SUCCESS_COLOR};
  }
  .env-cell-live svg path {
    stroke: ${SUCCESS_COLOR};
  }
  .KIWI-option .staging .ws-dropdown-sign, .KIWI-option .create .ws-dropdown-sign:hover,
  .env-cell-staging {
    color: ${ALT_COLOR};
  }
  .env-cell-dev {
    color: ${INACTIVE_COLOR};
  }
  /*------- File Uploader ---------------------*/
  .file-uploader {
    display: flex;
    flex-wrap: wrap;
    border: 2px solid ${theme.border.uploader};
    padding: 60px;
  }
  .file-uploader>.option {
    width: 100%;
    display: block;
  }
  .file-uploader .file-option {
    margin-bottom: 40px;
  }
  .file-uploader .download-url {
    border: none;
    border-bottom: 1px solid darkgrey;
    width: 49%;
    margin-left: 20px;
    display: inline-block;
    background: transparent;
    padding: 6px;
  }
  @media (max-width: 1020px) {
    .file-uploader .download-url {
      width: 30%;
    }  
  }
  .file-uploader .file-option >.url-option {
    padding: 14px 46px 14px 67px;
    font-size: 15px;
    font-weight: bold;
    color: ${BODY_COLOR};
  }
  .file-uploader .file-option > span {
    font-weight: bold;
    font-size: 18px;
    color: ${INFO_COLOR};
  }
  .file-uploader .option>svg {
    width: 50px;
    height: 40px;
    color: ${INFO_COLOR};
    margin: -10px 0px;
  }
  .file-uploader .option>span {
    padding: 14px 18px;
  }
  .file-uploader .option>button {
    margin-left: 88px;
  }
  /*------- Advanced-Tabs ---------------------*/
  .advanced-tabs-container{
    display: flex;
    flex-wrap: wrap;
  }
  .box-select{
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .secondary-box-select{
    width: 90%;
    margin: 7px 0px 0px 0px;
    text-align: left;
  }
  /*------- react-kiwi-dropdown ----------------------*/
  .KIWI-button{
    min-height: 100%;
  }
  .KIWI-single-arrow{
    margin: 0px;
  }
  /*------- react-data-table-component ----------------------*/
  .baseline-cells .rdt_TableCell {
    align-items: baseline !important;
  }
  .table-rows-empty {
    margin-bottom: 25px;
  }
  .table-small-font .rdt_TableCol,.table-small-font .rdt_TableRow,.table-small-font .rdt_Pagination{
    font-size: 13px !important;
  }
  .table-small-font .rdt_TableCell{
    padding-right: 15px !important;
  }
  .rdt_Pagination{
    margin-bottom: 5px;
  }
  .data-table-main-class{
    position: static !important;
    overflow: visible;
  }
  .data-table-main-class::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .data-table-main-class::-webkit-scrollbar-thumb {
    background-color: rgb(193 193 193);
    -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }
  .data-table-main-class > div{
    position: static !important;
  }
  .data-table-main-class > div > .rdt_Table{
    position: static !important;
  }
  .data-table-main-class > div > .rdt_Table > .rdt_TableBody{
    position: static !important;
  }
  .data-table-main-class > div > .rdt_Table > .rdt_TableBody > .rdt_TableRow > div:last-child{
    position: static !important;
  }
  .rdt_Pagination > div > select {
    margin: 0 8px 0 0;
  }
  .rdt_TableRow:nth-child(odd){
    background-color: #f0f4fc;
  }
  .rdt_TableRow > div > div{
    white-space: pre-line !important;
  }
  .rdt_TableRow > div , .rdt_TableCol{
    padding: 7px 4px 7px 0;
  }
  .rdt_TableHeader ~ header {
    padding: 0px !important;
    float: right;
    text-align: right;
    width: 250px;
    background: ${BODY_BACKGROUND};
    z-index: 99;
  }
  .rdt_TableHeader{
    display: none;
  }
  .rdt_TableCol,.rdt_TableRow,.rdt_Pagination{
    font-size: inherit !important;
    color: initial !important;
    border-bottom-style: none !important;
  }
  .wps-table-actions{
    width: auto;
    margin-left: auto;
  }
  .wps-table-actions > div{
    max-width: 230px !important;
    margin: 0 0 0 auto !important;
    position: static !important;
    float: right !important;
  }
  .employee-table .rdt_Table{
    min-width: auto !important;
  }
  .log-table .rdt_TableCell {
    display: block !important;
    padding-top: 10px !important;
  }
  /*------- File Manager ----------------------*/
  .oc-fm--toolbar__item > div , .oc-fm--dropdown-menu-item > div{
    max-width: 24px;
    max-height: 24px;
    margin: 0px 5px;
  }
  .oc-fm--dropdown-menu-item [data-test-id="svg-edit-btn"],
  .oc-fm--dropdown-menu-item [data-test-id="svg-view-btn"] {
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
  }
  [data-test-id="toolbar-item--view-btn"] {
    width: 29px !important;
    height: 29px !important;
  }
  [data-test-id="toolbar-item--create-file"]{
    width: 29px;
    height: 29px;
  }
  /*------- sidebar links ----------------------*/
  .link-hidden{
    display: none;
    overflow: hidden;
  }
  .pointer{
    cursor:pointer;
  }
  .primary-item{
    margin-bottom: 5px;
    font-size: 15px;
  }
  .primary-item > span{
    position:relative;
  }
  .primary-item > .arrow-down::after{
    content: '';
    position: absolute;
    right: -18px;
    top: 8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid white;
  }
  .primary-item > .arrow-up::after{
    content: '';
    position: absolute;
    right: -18px;
    top: 8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid white;
  }
  .secondary-item{
    margin-bottom: 5px;
    font-size: 13px;
    padding: 17px 0 0 5px;
  }
  .custom-nav-list{
    display: block !important;
    padding: 245px 0 0 15px !important;
  }
  .custom-nav-list > div{
    overflow: auto;
    height: 100%;
  }
  .custom-nav-list > div {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;;
      border-radius: 0px;
    }
  }
  /*------- customer-profile-setup-image-wrapper -------*/
  @media (max-width: 910px) {
  .customer-profile-setup-image-wrapper{
      display: none !important;
    }
  }

  /*------- Buttons Design ----------------------*/
  .btn-xs {
    max-height: 26px;
    font-size: 13px
  }
  .card-option--btn {
    ${ROUNDED_LIGHT_BUTTON}
    margin: 10px auto;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, #7083e2, #2268d1) !important;
  }
  .card-selected--btn {
    ${ROUNDED_DARK_BUTTON}
    background: #0db7e0;
    font-weight: bold;
  }
  .card-active--btn {
    ${ROUNDED_LIGHT_BUTTON}
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, ${WARNING_COLOR}, #ffc107) !important;
    margin: 12% auto 5%;
  }
  .card-option--btn:disabled {
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, rgba(225, 225, 225, 1), rgba(225, 225, 225, 1)) !important;
    box-shadow: 2px 1000px 1px rgba(225, 225, 225, 1) inset !important;
  }
  .next-light--btn {
    ${THIN_BUTTON}
  }
  .next-light--btn::after {
    ${ROUNDED_LIGHT_BUTTON_AFTER}
    background: linear-gradient(to left, #4CA8F7 0%, #8AECFE 90%);
  }
  .create-light--btn {
    ${THIN_BUTTON}
  }
  .create-light--btn::after {
    ${ROUNDED_LIGHT_BUTTON_AFTER}
    background: linear-gradient(to left, #37DF61 0%, #48FF21 90%);
  }
  .remove-light--btn {
    ${THIN_BUTTON}
  }
  .remove-light--btn::after {
    ${ROUNDED_LIGHT_BUTTON_AFTER}
    background: linear-gradient(to left, #FF7000 0%, #FFB721 90%);
  }
  /*------- Buttons Classes & Styles ----------------------*/
  .save--btn, .upload--btn, .create--btn, .add--btn, .assign--btn, .success--btn { 
    background: ${SUCCESS_BACKGROUND} !important;
    color: ${WHITE_COLOR} !important;
  }
  .update--btn, .edit--btn, .primary--btn, .next--btn, .action--read, .tmplt--btn, .preview--btn, .notice--btn, .search--btn { 
    background: ${PRIMARY_BACKGROUND} !important;
    color: ${WHITE_COLOR} !important;
  }
  .delete--btn, .danger--btn { 
    background: ${DANGER_BACKGROUND} !important;
    color: ${WHITE_COLOR} !important;
  }
  .remove--btn, .unassign--btn, .action--write, .warning--btn, .refresh--btn, .execute--btn, .sensitive--btn { 
    background: ${WARNING_BACKGROUND} !important;
    color: ${WHITE_COLOR} !important;
  }
  .back--btn, .info--btn, .prev--btn, .add-btn-info, .clipboard--btn { 
    background: ${INFO_BACKGROUND} !important;
    color: ${WHITE_COLOR} !important;
  }
  .alt--btn, .export--btn { 
    background: ${ALT_BACKGROUND} !important;
    color: ${WHITE_COLOR} !important;
  }
  .success2{
    background: rgb(107,197,187) !important;
    color: ${WHITE_COLOR} !important;
  }
  .close--btn, .cancel--btn { 
    background: #FFF !important;
    color: ${BLACK_COLOR} !important;
    border: 1px solid #868585;
  }
  .disabled--btn {
    background: linear-gradient(90deg, rgba(202,202,202,1) 0%, rgba(225,225,225,1) 100%) !important;
    color:black !important;
  }
  .text--btn {
    text-transform: none;
    background: none;
    color: ${PRIMARY_COLOR};
    font-size: 12px;
    text-align: left;
    text-decoration: underline;
    padding: 0;
    display: block;
  }
  .global-settings .subheader {
    margin-top: 25px;
  }
  /*------- Box Classes & Styles ----------------------*/
  .progress-box {
    background: ${WARNING_COLOR};
    color: ${WHITE_COLOR};
    display: block;
    padding: 4px;
    width: fit-content;
    margin-top: 15px;
  }
  /*------- monaco-editor ----------------------*/
  .monaco-editor .margin{
    background-color: #fffffe;
  }  
  .monaco-editor{
    padding-top: 20px;
  }
  /*------- Helpers ----------------------*/
  .center-spinner-loader{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-80%, -80%); 
  }
  #theList{
    position: absolute;
    top: 100%;
    min-width: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    background: ${WHITE_COLOR};
    border: 1px solid red;
    z-index: 10;
  }
  .list-li{
    display: block;
    padding: 8px 12px;
    cursor: pointer;
  }
  .editor-dialog-padding{
    padding: 55px 40px 40px 40px !important;
  }
  .websites-table-sort-select {
    margin-right: 6px;
  }
  .websites-table-refresh-icon{
    width: 26px;
    height: 26px;
    margin: 0px 6px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .websites-table-refresh-icon > svg{
    width: 100%;
    height: 100%;
    margin: 0 !important;
    color: #3ec3ff;
  }
  .custom-text-area {
    width: 70% !important;
    min-width: 800px;
    min-height: 50px;
    padding: 10px;
  }
  .text-word-break{
    width: 100%;
    word-break: break-word;
    text-align: left !important;
  }
  .modal-standard-btns-padding{
    padding: 9px 16px;
    border-radius: 5px;
  }
  .min-width-460{
    min-width: 460px;
  }
  .min-width-350{
    min-width: 350px;
  }
  .max-width-300{
    max-width: 300px;
  }
  .max-width-150{
    max-width: 150px;
  }
  .width-fit-content{
    width: fit-content;
  }
  .tooltip-relative-parent .custom-tip{ 
    position: relative !important; 
    top: 2px !important; 
  }
  .gray-bg{
    background: #939cb6 !important;
  }
  .display-flex-center-start{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .display-flex-center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .wps-textarea{
    color: inherit;
    border: 1px solid #A8B5D9;
    border-radius: 5px;
    padding: 10px;
    outline: 0px;
  }
  .customers-selections{
    display:flex;
    flex-wrap: wrap;
  }
  .customers-selections .elements-box{
    display: flex;
    width: 300px;
    max-width: 300px;
    margin: 6px 15px 25px 15px;
    padding: 26px 0 0 0;
    border: 2px solid #e0e9ff;
    border-radius: 5px;
    box-shadow: none;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .customers-selections .elements-box > div{
    width: 100%;
    font-weight: normal;
  }
  .customers-selections .elements-box > div:last-child {
    margin-top: auto;
    padding: 15px 0;
  }
  .customers-selections .box-icon > svg {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 10px;
    color: #398eff;
  }
  .customers-selections .selection-box-btn{
    margin: 10px auto;
  }
  .padding-right-12{
    padding-right: 12px;
  }
  .padding-left-0{
    padding-left: 0px !important;
  }
  .margin-left-0{
    margin-left: 0px !important;
  }
  .margin-left-18{
    margin-left: 18px;
  }
  .display-flex-nowrap{
    display: flex;
    flex-wrap: nowrap;
  }
  /* Chrome, Safari, Edge, Opera .... hide input type number arrows */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox .... hide input type number arrows */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .custom-bubble-li {
    display: flex;
    margin: 0 8px 8px 0;
    position: relative;
    background: #1e258e;
    padding: 0 13px 0 0;
    border-radius: 5px;
  }
  .custom-bubble-li .delete-bubble{
    position: absolute;
    right: 6px;
    top: 4px;
    color: ${WHITE_COLOR};
    cursor: pointer;
  }
  .display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .custom-add-btn{
    width: 10%;
    font-size: 25px;
    padding: 0;
    font-weight: bold;
    background: ${WHITE_COLOR};
    color: ${BLACK_COLOR};
    border: 1px solid #A8B5D9;
    border-radius: 5px;
  }
  .zIndex-2048{
    z-index: 2048;
  }
  .packages-table .activate-cell:disabled {
    background-color: ${theme.color.checkboxOn} !important;
  }
  label.default-checkbox-label {
    margin: 6px 0 !important;
  }
  .custom-checkbox-label{
    min-height: 30px;
    margin: 0 0 0 10px;
    padding: 2px 0;
  }
  .custom-row > span{
    margin: 0px 0px 12px 10px;
  }
  .custom-icon-position {
    position: absolute;
    right: -80px;
    bottom: 10px;
  }
  .alt-icon-position {
    position: relative;
    right: -50%;
    bottom: 25px;
  }
  .__react_component_tooltip{
    max-width: 45%;
    z-index: 1050;
  }
  .font-bold {
    font-weight: bold !important;
  }
  .font-small {
    font-size: 13px !important;
  }
  .font-italic {
    font-style: italic;
  }
  .font-underlined {
    text-decoration: underline !important;
  }
  .no-underline {
    text-decoration: none !important;
  }
  .active-color {
    background-color: #5096ff !important;
  }
  .complete-color {
    background-color: #2bca5e !important;
  }
  .example-background {
    background-color: ${EXAMPLE_BACKGROUND} !important;
  }
  .warning-color{
    background-color: ${WARNING_COLOR} !important
  }
  .danger-color{
    background-color: ${DANGER_COLOR} !important;
  }
  .success-color{
    background-color: ${SUCCESS_COLOR} !important;
  }
  .primary-color{
    background-color: ${PRIMARY_COLOR} !important;
  }
  .disabled-font-color{
    color: ${DISABLED_COLOR} !important;
  }
  .alt-font-color{
    color: ${ALT_COLOR} !important;
  }
  .inactive-font-color{
    color: ${INACTIVE_COLOR} !important;
  }
  .warning-font-color{
    color: ${WARNING_COLOR} !important
  }
  .danger-font-color, .delete-color{
    color: ${DANGER_COLOR} !important;
  }
  .success-font-color{
    color: ${SUCCESS_COLOR} !important;
  }
  .primary-font-color{
    color: ${PRIMARY_COLOR} !important;
  }
  .info-font-color{
    color: ${INFO_COLOR} !important;
  }
  .display-none {
    display:none !important;
  }
  .visibility-hidden{
    visibility: hidden !important;
  }
  .display-inline-block{
    display: inline-block
  }
  .website-display-actions .class-link {
    display: inline !important;
  }
  .website-display-actions a.cell-subheader {
    position: relative;
    top: -2px;
  }
  .website-display-actions .subheader-post {
    font-size: 13px !important;
    margin-left: 4px;
  }
  .custom-back-btn{
    display: inline-block;
    float: right;
    margin-top: 36px;
    margin-right: 10px;
  }
  .width-100 {
    width: 100% !important;
  }
  .width-50 {
    width: 50% !important;
  }
  .width-30{
    width: 30% !important;
    min-width: 400px;
  }
  .margin-left-6{
    margin-left: 6px !important;
  }
  .margin-right-25{
    margin-right: 25px !important;
  }
  .margin-top-20{
    margin-top: 20px !important;
  }
  .margin-top-12{
    margin-top: 12px !important;
  }
  .margin-top-5{
    margin: 5px 0px 0px 0px;
  }
  .margin-left-10-perc {
    margin-left: 10% !important;
  }
  .margin-left-25-perc {
    margin-left: 25% !important;
  }
  .margin-bottom-12{
    margin-bottom: 12px !important;
  }
  .margin-bottom-24{
    margin-bottom: 24px !important;
  }
  .margin-bottom-70{
    margin-bottom: 70px !important;
  }
  .margin-bottom-30{
    margin-bottom: 30px !important;
  }
  .margin-bottom-0{
    margin-bottom: 0px !important;
  }
  .margin-top-bottom-70{
    margin-bottom: 70px !important;
  }
  .custom-table-margin{
    margin: 50px 24px 0px 24px;
  }
  .custom-bread-crumbs{
    height: 70px;
    border: none;
    box-shadow: none;
    min-height: 50px;
    margin: 10px 0 -30px 0px;
    padding: 0;
  }
  .advanced-section-header{
    display: block;
    width: 100%;
  }
  .margin-auto {
    margin: auto;
  }
  .margin-sides-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .margin-24{
    margin: 0px 24px;
  }
  .margin-top-24{
    margin-top: 24px;
  }
  .margin-top-bottom-24{
    margin: 24px 0px;
  }
  .margin-0{
    margin: 0px !important;
  }
  .padding-0{
    padding: 0px;
  }
  .\!padding-0{
    padding: 0px !important;
  }
  .ws-box .custom-tip svg {
    height: 0.9em;
  }
  .result-box {
    border: 1px solid ${theme.border.light};
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    display: block;
    width: max-content;
  }
  .result-box .result-box-icon,
  .result-box .wps-bubble {
    vertical-align: middle;
    padding-right: 5px;
  }
  .result-box .wps-bubble {
    padding-right: 0px;
    margin: 0;
  }
  .result-box .result-box-icon svg {
    height: 1.2em;
    width: 1.2em;
  }
  .warning-box {
    border: 1px solid ${theme.border.orange};
    background-color: ${theme.color.lightOrange};
    color: ${theme.color.darkOrange};
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    display: block;
    max-width: fit-content;
  }
  .rdt_TableCell .warning-box {
    font-size: 11px;
    padding: 4px;
    margin-bottom: 2px;
  }
  .success-box {
    border: 1px solid ${theme.border.green};
    background-color: ${theme.color.lightGreen};
    color: ${theme.color.darkGreen};
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    display: block;
    max-width: fit-content;
  }
  .notice-box {
    border: 1px solid ${theme.border.mediumBlue};
    background-color: ${theme.color.lightBlue};
    color: ${theme.color.shadeBlue};
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    display: block;
    max-width: fit-content;
  }
  .error-box {
    border: 1px solid ${theme.border.red};
    background-color: ${theme.color.lightRed};
    color: ${theme.color.danger};
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    display: block;
    max-width: fit-content;
  }
  .box-content, .box-content > div {
    display: contents;
  }
  .box-content .goto-link {
    color: inherit;
  }
  .box-shadow-none {
    box-shadow: none;
    margin-top: 24px;
  }
  .loadingspinner {
    pointer-events: none;
    width: 25px;
    height: 25px;
    border: 3px solid red;
    border-color: transparent;
    border-top-color: #3E67EC;
    background-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: loadingspin 1s linear infinite;
    animation: loadingspin 1s linear infinite;
  }
  .loadingspinner:after{
    display: none !important;
  }
  @keyframes loadingspin {
    100% {
        transform: rotate(360deg)
    }
  }
  `;

export default GlobalStyles;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 200px;
`;

export const Content = styled.div`
  padding: 24px;
`;

export const GlobalLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
