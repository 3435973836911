import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg
} from 'store/global/globalActions';
import WebsiteService from 'services/website';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableActions from 'components/wpstaq/WPSDataTable/WPSTableActions';
import { timezoneSelector } from 'store/me/meSelectors';
import FirewallHelper from 'helpers/firewall';
import env from 'config/env';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';

const FirewallBannedIPs = ({ dispatch, setTableData, tableData, loading, rowsPerPage, showWebsite }) => {
  const [lockedIPs, setLockedIPs] = useState([]);
  const timezone = useSelector(timezoneSelector);

  // ------------------------------
  // API Calls
  // ------------------------------

  const reportSafeIP = (row) => {
    const data = {
      website_slug: row.app,
      ip_address: row.ip,
      reason: `Reported as safe by ${UserHelper.getEmail()} via ${env.getBrandShortName()} dashboard.`
    };
    if (lockedIPs.includes(row.ip)) {
      dispatch(setGlobalPleaseWaitMsg(`The IP ${row.ip} is already being processed.`))
      return;
    };
    setLockedIPs(prev => [...prev, row.ip]);
    dispatch(setGlobalPleaseWaitMsg(`The IP ${row.ip} is being reported.`))
    WebsiteService.reportSafeIP(data)
    .then(() => {
      dispatch(setGlobalSuccessMsg({ id: row.ip, model: 'IP address', action: 'reported as safe', onId: row.app }));
      setTableData(prev => prev.map(item => item.ip === row.ip ? { ...item, reported_safe: true } : item));
    })
    .catch(err => dispatch(setGlobalErrorMsg(err)))
    .finally(() => setLockedIPs(prev => prev.filter(item => item !== row.ip)));
  };
  
  // ------------------------------
  // Table Actions
  // ------------------------------

  const actions = [
    {
      value: 'Report Safe',
      onClick: row => reportSafeIP(row),
      doHide: row => FirewallHelper.getIPBanStatus(row) !== 'banned',
    },
  ];

  const headers = [
    {
      name: 'IP Address',
      selector: 'ip',
      searchable: true,
      width: '12%',
      cell: row => JsxHelper.createBubble({
        text: row.ip,
        tooltip: row.hostname || 'Failed to resolve hostname.',
        background: 'info',
        margin: '0 0 0 -4px',
      }),
    },
    {
      name: 'Country',
      selector: 'country_code',
      width: (showWebsite ? 6 : 7) + '%', // -1
      searchable: true,
      sortable: true,
      style: {
        paddingLeft: '5px !important',
      },
      cell: row => FirewallHelper.displayCountryFlag(row),
    },
    showWebsite ? { name: 'Server', selector: 'cluster', width: '13%', } : null,
    showWebsite ? { name: 'Website', searchable: true, selector: 'app', width: '13%', } : null,
    {
      name: 'Ban Code',
      selector: 'ban_code',
      searchable: true,
      width: (showWebsite ? 14 : 18) + '%', // -6
      cell: row => JsxHelper.createBubble({
        text: row.ban_code,
        tooltip: row.ban_trigger,
        color: 'info',
        margin: '0 0 0 -6px',
      }),
    },
    {
      name: 'Status',
      selector: 'status',
      searchable: true,
      width: (showWebsite ? 9 : 11) + '%', // -3
      cell: row => FirewallHelper.displayRowStatus(row)
    },
    {
      name: 'Risk Level',
      selector: 'high_risk',
      searchable: false,
      width: (showWebsite ? 10 : 15) + '%', // -3
      cell: row => FirewallHelper.displayRiskLevel(row),
    },
    JsxHelper.createTableTimeHeader('banned_at', timezone, (showWebsite ? 12 : 14) + '%', 'Ban Time'),
    {
      name: '',
      width: (showWebsite ? 11 : 23) + '%', // -4
      button: true,
      cell: row => <TableActions
        actions={actions}
        item={row}
        customStyle={{ maxWidth: '200px', minWidth: 'unset', marginRight: '5px' }}
      />
    },
  ].filter(item => !!item);

  return (
    <Fragment>
      <WPSDataTable
        dataKey='id'
        loading={loading}
        columns={headers}
        body={tableData}
        rowsPerPage={rowsPerPage || 25}
      />
    </Fragment>
  );
};

export default FirewallBannedIPs;
