import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import ReportService from 'services/report';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { useSelector } from 'react-redux';
import PlanHelper from 'helpers/plan';
import JsxHelper from 'helpers/jsx';
import ArrayHelper from 'helpers/array';
import UserHelper from 'helpers/user';
import DateHelper from 'helpers/date';
import DialogHelper from 'helpers/dialog';
import { partnersSelector } from 'store/user/userSelectors';
import { billingPeriodSelector } from 'store/billing/billingSelectors';
import { reportsSettingsSelector } from 'store/setting/settingSelectors';
import { updateSingleReportSettings } from 'store/setting/settingActions';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { userSlug } from 'store/me/meSelectors';
import { useDispatch } from 'react-redux';
import 'components/package/packages.css';

const SubscriptionsHighUsage = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const billingPeriod = useSelector(billingPeriodSelector);
  const billingStart = DateHelper.toUTCFormat(billingPeriod[0], 'YYYY-MM-DD'); // '2021-01-01
  const billingEnd = DateHelper.toUTCFormat(billingPeriod[1], 'YYYY-MM-DD'); // '2021-01-31
  const allPartners = useSelector(partnersSelector);
  const currentUserSlug = useSelector(userSlug);
  const reportsSettings = useSelector(reportsSettingsSelector);
  const globalReportSettings = reportsSettings.subscriptions_high_usage || {};
  const [selectedPartner, setSelectedPartner] = useState(UserHelper.isPartner() ? currentUserSlug : null);
  const mounted = useRef(true)
  const dispatch = useDispatch();

  useEffect(() => {
    fetchHighUsageData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const saveSettings = () => {
    const _emails = modal.cc_emails.trim();
    const data = {
      name: 'subscriptions_high_usage',
      settings: {
        'cc_emails': _emails ? _emails.split(',').map(e => e.trim()) : [],
      },
    }
    setModal({ ...modal, loading: true });
    dispatch(updateSingleReportSettings(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'Report settings', action: 'updated' }));
        setModal(false);
      })
      .catch(() => setModal({ ...modal, loading: false }));
  }

  const fetchHighUsageData = (_partnerSlug) => {
    _partnerSlug = _partnerSlug || selectedPartner;
    if (!_partnerSlug) return;
    const data = {
      partner_slug: _partnerSlug,
    };
    setLoading(true);
    ReportService.listSubscriptionsHighUsage(data)
      .then((res) => {
        if (!mounted.current) return null;
        // The result is an object with keys as the subscription name and a data object as the value.
        const tableData = [];
        for (const key of Object.keys(res)) {
          const data = res[key];
          for (const feature of data.features) {
            tableData.push({
              key: key + feature.human_name,
              name: data.subscription_name,
              plan_name: data.plan_name,
              human_name: feature.human_name,
              human_stats: feature.human_stats,
              percent: feature.percent,
            });
          }
        }
        setTableData(tableData);
      })
      .finally(() => setLoading(false));
  }

  const breadcrumbs = JsxHelper.createBreadcrumbs('Subscriptions High Usage', 'reports');

  const headers = [
    {
      name: ' ',
      selector: 'icon',
      width: '8%',
      sortable: true,
      sortableValue: 'percent',
      cell: row => JsxHelper.createIcon({
        ...PlanHelper.featureUsageToIconDetails(row),
        style: { fontSize: '1.8em' },
      }),
    },
    {
      name: 'Subscription',
      selector: 'name',
      searchable: true,
      width: '30%',
      cell: row => {
        return JsxHelper.createTableMultiLineCell({
          header: row.name,
          subheader: row.plan_name,
        })
      },
    },
    {
      name: 'Feature',
      selector: 'human_name',
      searchable: true,
      width: '20%',
    },
    {
      name: 'Current Usage',
      sortableValue: 'percent',
      selector: 'human_stats',
      width: '40%',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Subscriptions High Usage</TitleBar.Title>
        <TitleBar.Actions>
          {UserHelper.isAdminOrAgent() && (JsxHelper.createSelectInput({
            name: 'partner_slug_option',
            value: selectedPartner,
            options: ArrayHelper.buildSelectOptions(allPartners, 'display_name', 'slug'),
            sortOff: true,
            isSearchable: true,
            onChange: e => {
              const partnerSlug = e.target.value;
              setSelectedPartner(partnerSlug);
              fetchHighUsageData(partnerSlug);
            },
            placeholder: 'Select partner...',
          }))}
          {UserHelper.isPartnerOrEmployee() && (JsxHelper.createButton({
            label: 'Email Alerts',
            onClick: () => setModal({ name: 'alerts', cc_emails: (globalReportSettings.cc_emails || []).join(', ') }),
          }))}
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      {JsxHelper.createTipBox(
        `${billingStart} - ${billingEnd}`,
        false,
        'Billing Period',
        { margin: '40px 0 0 20px' }
      )}
      <Content>
        <WPSDataTable
          customClass='subscriptions-high-usage'
          columns={headers}
          body={tableData}
          loading={loading}
          rowsPerPage={100}
          dataKey={'key'}
        />
      </Content>
      {modal && modal.name === 'alerts' && DialogHelper.inputs({
        title: 'Email Alerts',
        iconColor: 'primary',
        btnColor: 'primary',
        btnText: 'Save',
        loading: modal.loading,
        onConfirm: saveSettings,
        onClose: () => setModal(false),
        inputs: [{
            name: 'cc_emails',
            type: 'text',
            label: 'Additional Notification Emails',
            value: modal.cc_emails,
            onChange: (e) => setModal({ ...modal, cc_emails: e.target.value }),
            placeholder: 'Enter email addresses separated by commas',
          }],
      })}
    </Fragment>
  );
}

export default SubscriptionsHighUsage;
