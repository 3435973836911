import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useSelector, useDispatch } from 'react-redux';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { useForm } from 'react-hook-form';
import WebsiteService from 'services/website';
import { unassignCustomer } from 'store/website/websiteActions';
import DialogHelper from 'helpers/dialog';
import JsxHelper from 'helpers/jsx';
import ArrayHelper from 'helpers/array';
import { customersSelector } from 'store/customer/customerSelectors';
import { customerWebsitePlansSelector } from 'store/customerBilling/customerBillingSelectors';
import { createNewSubscription } from 'store/customerBilling/customerBillingActions';
import WebsiteHelper from 'helpers/website';
import DateHelper from 'helpers/date';
import UserHelper from 'helpers/user';

const Account = ({ website }) => {
  useTitle('Website Account & Billing');
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unassignLoading, setUnassignLoading] = useState(false);
  const [partnerSubscription, setPartnerSubscription] = useState(false);
  const [customerSubscription, setCustomerSubscription] = useState(false);
  const [customerSubscriptionEmail, setCustomerSubscriptionEmail] = useState(false);
  const customers = useSelector(customersSelector);
  const plans = useSelector(customerWebsitePlansSelector);
  const mounted = useRef(true);
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (!partnerSubscription && !customerSubscription) {
      fetchSubscriptionsData();
    }

    return () => {
      mounted.current = false;
    }
    // eslint-disable-next-line
  }, []);

  const fetchSubscriptionsData = (callback) => {
    setLoading(true);
    const data = {
      website_slug: website.slug,
    };
    WebsiteService.getSubscriptions(data).then((res) => {
      setPartnerSubscription(res.find((s) => s.subscriber_type === 'partner'));
      const customer = res.find((s) => s.subscriber_type === 'customer');
      if (customer) {
        setCustomerSubscription(customer);
        setCustomerSubscriptionEmail(customer.subscriber_email);
      }
      callback && callback();
    }).finally(() => setLoading(false));
  }

  const onClickAssignPlan = () => setModal({
    name: 'assign-plan',
    loading: false,
    customer_slug: '',
    starts_at: DateHelper.getDateOnly(),
  });

  const onClickUnassignCustomer = () => {
    setUnassignLoading(true);
    dispatch(unassignCustomer({ website_slug: website.slug }))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Customer', action: 'unassigned', fromId: WebsiteHelper.getLabel(website) })))
      .finally(() => setUnassignLoading(false));
  }

  const onChangeModalInput = (e) => {
    const { name, value } = e.target;
    setModal(prev => ({ ...prev, [name]: value }));
  };

  const createNewCustomerSubscription = () => {
    const data = {
      resource_slug: website.slug,
      customer_slug: modal.customer_slug,
      plan_slug: modal.plan_slug,
      resource_type: 'website',
      name: WebsiteHelper.getLabel(website),
      starts_at: modal.starts_at,
    };
    setModal({ ...modal, loading: true });
    dispatch(createNewSubscription(data))
    .then(() => {
      dispatch(setGlobalSuccessMsg({ forId: modal.customer_slug, model: 'Subscription', action: 'created' }));
      fetchSubscriptionsData(() => setModal(false));
    }).catch((err) => {
      dispatch(setGlobalErrorMsg(err))
      setModal({ ...modal, loading: false });
    });
  };

  const renderListItemOrLoadIfNotExists = (obj, key, label, _default) => 
    <p><strong>{label}:</strong> {obj ? obj[key] : (_default?_default:`No ${label.toLowerCase()} defined`)}</p>;

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Account / Billing</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
      View your subscribed plans and manage any plans assigned to your clients. If no client plans are assigned, you can easily assign a new plan to start charging clients for your services.
      </p>
      <Content>
        <Fragment>
          <div className='info-box'>
            <h4>Billing</h4>
            {!loading ? <Fragment>
            <p>{renderListItemOrLoadIfNotExists(partnerSubscription, 'plan_name', 'Plan')}</p>
            <p>{renderListItemOrLoadIfNotExists(partnerSubscription, 'name', 'Subscription')}</p>
            </Fragment> : JsxHelper.createLoadingSpinner()}
          </div>
          <div className='info-box'>
            <h4>Client</h4>
            {!loading ? <Fragment>
              <p>{renderListItemOrLoadIfNotExists(customerSubscription, 'subscriber_email', 'Client', website.customer_email)}</p>
              <p>{renderListItemOrLoadIfNotExists(customerSubscription, 'plan_name', 'Plan')}</p>
              <p>{renderListItemOrLoadIfNotExists(customerSubscription, 'name', 'Subscription')}</p>
            </Fragment> : JsxHelper.createLoadingSpinner()}
            <div className='display-flex action-buttons'>
              { // If the website doesn't have a customer subscription.
              !customerSubscriptionEmail && !customerSubscription && !loading && (JsxHelper.createButton({
                label: 'Assign Plan',
                style: { marginTop: '16px', width: 'auto' },
                onClick: onClickAssignPlan,
              }))}
              { // If the website doesn't have a subscription but has a customer assigned.
              !UserHelper.isAdminOrAgent() && website.customer_email && !customerSubscription && !loading && (JsxHelper.createButton({
                label: 'Unassign Client',
                style: { marginTop: '16px', width: 'auto' },
                onClick: onClickUnassignCustomer,
                loading: unassignLoading,
              }))}
            </div>
          </div>
        </Fragment>
      </Content>
      {modal && modal.name === 'assign-plan' && DialogHelper.inputs({
        title: 'Create New Subscription',
        icon: 'create',
        iconColor: 'success',
        btnText: 'Create',
        onConfirm: handleSubmit(createNewCustomerSubscription),
        onClose: () => setModal(false),
        loading: modal.loading,
        register,
        inputs: [{
          name: 'plan_slug',
          label: 'Choose Plan',
          type: 'select',
          options: ArrayHelper.buildSelectOptions(plans, 'name', 'slug'),
          value: modal.plan_slug,
          required: true,
          onChange: onChangeModalInput,
          errors,
        },{
          name: 'customer_slug',
          label: 'Select Client',
          after: JsxHelper.createRelativeLink('/clients/accounts', 'Create new client', 'primary-font-color'),
          afterStyle: { fontSize: '12px' },
          type: 'select',
          options: ArrayHelper.buildSelectOptions(customers, 'display_name', 'slug'),
          value: modal.customer_slug,
          required: true,
          onChange: onChangeModalInput,
          errors,
        },{
          name: 'starts_at',
          label: 'Start Date',
          type: 'date',
          min: DateHelper.getDateOnly(),
          value: modal.starts_at,
          required: true,
          onChange: onChangeModalInput,
          errors,
        }]
      })}
    </Container>
  );
};

export default Account;
