import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import { TitleBar } from "styles/layout/titlebar";
import { Content } from "styles/globalStyles";
import JsxHelper from "helpers/jsx";
import { partnersSelector } from 'store/user/userSelectors';
import { websitesSelector } from 'store/website/websiteSelectors';
import ArrayHelper from 'helpers/array';
import "../common/reports.css";
import { isEmptyOrNull, exportCsvToFile } from "helpers";
import ReportService from 'services/report';
import DialogHelper from 'helpers/dialog';

const PartnersFeatures = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [expandedPartner, setExpandedPartner] = useState(null);
  const [expandAll, setExpandAll] = useState(false);
  const [collapsedPartners, setCollapsedPartners] = useState([]); // Used when expanding/collapsing all
  const viewerDefaults = { title: '', isOpen: false, data: {} };
  const [viewer, setViewer] = useState({ ...viewerDefaults });
  const allPartners = useSelector(partnersSelector);
  const allWebsites = useSelector(websitesSelector);

  const breadcrumbs = JsxHelper.createBreadcrumbs(
    `Partners Features Usage`,
    "reports"
  );

  // --------------------------------------------------
  // Lifecycle
  // --------------------------------------------------

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  // --------------------------------------------------
  // API
  // --------------------------------------------------

  const loadData = async () => {
    setLoading(true);
    ReportService.listPartnersFeaturesUsage()
      .then(res => {
        // Group websites by partner slug
        const partnerWebsites = {};
        allWebsites.forEach((website) => {
          const partnerSlug = website.partner_slug;
          if (!partnerWebsites[partnerSlug]) {
            partnerWebsites[partnerSlug] = { total: 0, live: 0, staging: 0, dev: 0 };
          }
          partnerWebsites[partnerSlug].total++;
          if (website.is_live) {
            partnerWebsites[partnerSlug].live++;
          } else if (website.is_staging) {
            partnerWebsites[partnerSlug].staging++;
          } else {
            partnerWebsites[partnerSlug].dev++;
          }
        });
        // Format the data
        const formattedData = Object.keys(res).map((key) => {
          const partnerEmail = key;
          const partner = allPartners.find((p) => p.email === partnerEmail);
          if (!partner) {
            return null;
          } else if (!partnerWebsites[partner.slug]) {
            partnerWebsites[partner.slug] = { total: 0, live: 0, staging: 0, dev: 0 };
          }
          return {
            partner_email: partner.email,
            features: res[key],
            subtitle: `Total: ${partnerWebsites[partner.slug].total} | Live: ${partnerWebsites[partner.slug].live} | Staging: ${partnerWebsites[partner.slug].staging} | Dev: ${partnerWebsites[partner.slug].dev}`,
          };
        }).filter(Boolean);
        setTableData(formattedData);
        setFilteredData(formattedData);
      }).finally(() => setLoading(false));
  };

  // --------------------------------------------------
  // Export Functionality
  // --------------------------------------------------

    const handleExportFile = () => {
      if (isEmptyOrNull(tableData)) {
        return;
      }
      const exportData = tableData.map(partner => {
        const row = { Partner: partner.partner_email };
        partner.features.forEach(feature => {
          row[feature.label] = feature.enabled ? "Enabled" : "Disabled";
        });
        return row;
      });
      exportCsvToFile(exportData, `PartnersFeaturesUsage-${new Date().toISOString()}.csv`);
    }

  // --------------------------------------------------
  // Search Functionality
  // --------------------------------------------------

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    if (term === 'all partners') {
      setFilteredData(tableData);
      return;
    }
    const filtered = tableData.filter((partner) =>
      partner.partner_email.toLowerCase().includes(term)
    );
    setFilteredData(filtered);
    // Automatically expand the last partner in the filtered list
    if (filtered.length === 1) {
      setExpandedPartner(filtered[0].partner_email);
    } else {
      setExpandedPartner(null);
    }
  };

  // --------------------------------------------------
  // Render
  // --------------------------------------------------

  const toggleFeatures = (email) => {
    if (expandAll) {
      if (collapsedPartners.includes(email)) {
        setCollapsedPartners((prev) => prev.filter((partner) => partner !== email));
      } else {
        setCollapsedPartners((prev) => [...prev, email]);
      }
      return;
    }
    setExpandedPartner((prev) => (prev === email ? null : email))
  };

  const isExpanded = (email) => {
    if (expandAll) {
      return !collapsedPartners.includes(email);
    }
    return expandedPartner === email || expandAll
  };

  const handleViewerClose = useCallback(() => {
    setViewer({ ...viewerDefaults });
  }, [viewerDefaults]);

  const viewUsage = (partner, feature) => {
    const _feature = tableData.find((p) => p.partner_email === partner).features.find((f) => f.label === feature);
    if (!_feature) {
      DialogHelper.alert("No data available for this feature");
      return;
    };
    setViewer({
      title: `${partner} - ${_feature.label} Usage`,
      isOpen: true,
      data: _feature ? _feature.data : {},
    });
  }

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          Partners Features Usage
        </TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createSelectInput({
            name: 'partner_slug_option',
            value: selectedPartner,
            options: ArrayHelper.buildSelectOptions(allPartners, 'display_name', 'slug', 'All Partners'),
            isSearchable: true,
            onChange: (e) => {
              handleSearch(e);
              setSelectedPartner(e.target.value);
            },
            placeholder: 'Select partner...',
            sortOff: true
          })}
          {JsxHelper.createButton({
            label: 'Export',
            onClick: handleExportFile,
          })}
          {JsxHelper.createButton({
            label: expandAll ? "Collapse All" : "Expand All",
            onClick: () => setExpandAll(prev => !prev),
          })}
          {JsxHelper.createBackButton("/reports")}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <div className="partners-cards-container">
          {loading && JsxHelper.createTableLoader()}
          {!loading &&
            filteredData.map((partner) => (
              <div key={partner.partner_email} className="partner-card">
                <div className="partner-card-header" onClick={() => toggleFeatures(partner.partner_email)}>
                  <div><span className="partner-title">{partner.partner_email}</span> <span className="partner-subtitle">{partner.subtitle}</span></div>
                  <span className={`toggle-indicator ${isExpanded(partner.partner_email) ? "open" : ""}`}>
                    {isExpanded(partner.partner_email) ? "▼ Hide Features" : "► Show Features"}
                  </span>
                </div>
                {isExpanded(partner.partner_email) && (
                  <div className="partner-features">
                    <table className="features-table">
                      <thead>
                        <tr>
                          <th>Feature</th>
                          <th>Status</th>
                          <th>Usage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partner.features.map((feature, index) => (
                          <tr key={index}>
                            <td>{feature.label}</td>
                            <td>
                              {JsxHelper.createButton({
                                label: feature.enabled ? "Enabled" : "Disabled",
                                classes: `feature-status-badge ${feature.enabled ? "success--btn" : "danger--btn"}`,
                                small: true,
                              })}
                            </td>
                            <td>
                              {feature.enabled ? JsxHelper.createButton({
                                label: "View Usage",
                                classes: "info--btn",
                                small: true,
                                onClick: () => viewUsage(partner.partner_email, feature.label)
                              }) : 'No data'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
          {!loading && filteredData !== false && isEmptyOrNull(filteredData) && (
            <div className="no-data">No data available</div>
          )}
        </div>
      </Content>
      {viewer.isOpen && DialogHelper.jsonViewer({
        onClose: handleViewerClose,
        data: viewer.data,
      })}
    </Fragment>
  );
};

export default PartnersFeatures;
