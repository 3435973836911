import { postRequest } from '../config/axiosRequest';
import env from '../config/env';

// Filters WP packages in all websites by a given criteria.
const filterAppsInstalledPacakges = data => postRequest({
  url: env.buildApiEndpoint(`reports/apps-wp-packages`),
  data,
})

// Lists nginx errors in all websites.
const listAppsNginxErrors = data => postRequest({
  url: env.buildApiEndpoint(`reports/apps-nginx-errors`),
  data,
})

// Lists packages update history.
const listPackagesUpdateHistory = data => postRequest({
  url: env.buildApiEndpoint(`reports/packages-update-history`),
  data,
})

// Lists subscriptions with high usage.
const listSubscriptionsHighUsage = data => postRequest({
  url: env.buildApiEndpoint(`reports/subscriptions-high-usage`),
  data,
})

// Lists sent emails history.
const listSentEmailsHistory = data => postRequest({
  url: env.buildApiEndpoint(`reports/sent-emails-history`),
  data,
})

// Lists partners features usage.
const listPartnersFeaturesUsage = data => postRequest({
  url: env.buildApiEndpoint(`reports/partners-features-usage`),
  data,
})

const saveReportSettings = data => postRequest({
  url: env.buildApiEndpoint(`reports/${data.name}/settings`),
  data,
})

const ReportService = {
    filterAppsInstalledPacakges,
    listAppsNginxErrors,
    listPackagesUpdateHistory,
    listSubscriptionsHighUsage,
    listSentEmailsHistory,
    saveReportSettings,
    listPartnersFeaturesUsage,
};

export default ReportService;