import React, { Fragment, useState, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import { partnersSelector } from 'store/user/userSelectors';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import StringHelper from 'helpers/string';
import { getAllInovices } from 'store/billing/billingActions';

const PartnersBilling = () => {
  const allWebsites = useSelector(websitesSelector);
  const allPartners = useSelector(partnersSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    dispatch(getAllInovices({
      partner_slug: null,
      page: 1,
      limit: 10000,
    }))
      .then(res => initTableData(res))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const initTableData = (allInvoices) => {
    const _data = [];
    allPartners.forEach(partner => {
      const data = {};
      const websites = allWebsites.filter(website => website.partner_slug === partner.slug);
      const invoices = allInvoices.filter(invoice => invoice.recipient_slug === partner.slug);
      // Website
      data.num_total = websites.length;
      data.num_live = websites.filter(website => website.is_live).length;
      data.num_staging = websites.filter(website => website.is_staging).length;
      data.num_dev = data.num_total - data.num_live - data.num_staging;
      // Invoices
      data.num_invoices = invoices.length;
      data.paid_invoices = invoices.filter(invoice => invoice.is_paid);
      data.num_paid_invoices = data.paid_invoices.length;
      data.sum_paid_invoices = 0;
      data.paid_invoices.forEach(invoice => data.sum_paid_invoices += parseInt(invoice.total));
      data.sum_paid_invoices = data.sum_paid_invoices.toFixed(2);
      data.invoice_currency = invoices.length ? invoices[0].currency : 'USD';
      // Details
      data.email = partner.email;
      data.slug = partner.slug;
      data.display_name = partner.display_name || `${partner.first_name||''} ${partner.last_name||''}`;
      data.created_at = partner.created_at;
      _data.push(data);
    });
    _data.sort((a, b) => b.num_total - a.num_total);
    setTableData(_data);
  }

  const headers = [
    {
      name: 'Partner',
      selector: 'slug',
      sortable: true,
      searchable: true,
      width: '30%',
      cell: (row) => {
        TableHelper.customizeCellValue(row, 'partner', row.slug + ' ' + row.email);
        return JsxHelper.createTableMultiLineCell({
          header: row.display_name,
          subheader: row.email,
        })
      }
    },
    JsxHelper.createTableTextHeaderWithCallback(
      'total_sites',
      'Websites',
      '20%',
      row => {
        const livePerc = row.num_live ? ((row.num_live / row.num_total) * 100).toFixed(2) : 0;
        return JsxHelper.createTableMultiLineCell({
          header: row.num_total || '0',
          subheader: `${row.num_live} live (${livePerc}%)`,
        });
      },
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'invoices',
      'Invoices',
      '20%',
      row => {
        const paidPerc = row.num_paid_invoices ? ((row.num_paid_invoices / row.num_invoices) * 100).toFixed(2) : 0;
        return JsxHelper.createTableMultiLineCell({
          header: row.num_invoices || '0',
          subheader: `${row.num_paid_invoices} paid (${paidPerc}%) - ${StringHelper.numberFormat(row.sum_paid_invoices)} ${row.invoice_currency}`,
        });
      },
    ),
  ];

  const breadcrumbs = JsxHelper.createBreadcrumbs('Partners Billing', 'reports');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Partners Billing</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable loading={loading} columns={headers} body={tableData} />
      </Content>
    </Fragment>
  );
};

export default PartnersBilling;
