import React, { useState, useEffect } from 'react';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import CloudflareService from 'services/cloudflare';
import { useSelector, useDispatch } from 'react-redux';
import useTitle from 'hooks/useTitle';
import JsxHelper from 'helpers/jsx';
import env from 'config/env';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import DialogHelper from 'helpers/dialog';
import TableHelper from 'helpers/table';
import 'components/stepper/stepper.css';
import { getErrorMsg, isEmptyOrNull, isUndefined } from 'helpers';
import useModal from 'hooks/useModal';
import useConfirm from 'hooks/useConfirm';
import { fetchWebsite } from 'store/website/websiteActions';
import { websitesSelector } from 'store/website/websiteSelectors';
import WebsiteHelper from 'helpers/website';
import CloudflareHelper from 'helpers/cloudflare';
import UrlHelper from 'helpers/url';
import StringHelper from 'helpers/string';
import { setGlobalErrorMsg, setGlobalPleaseWaitMsg, setGlobalSuccessMsg } from 'store/global/globalActions';
import { createWebsiteCloudflareZone, connectWebsiteCloudflareZone, syncWebsiteCloudflareZone, refreshWebsiteCloudflareZone } from 'store/website/websiteActions';
import ArrayHelper from 'helpers/array';

const Cloudflare = () => {
  useTitle('Cloudflare');
  const breadcrumbs = JsxHelper.createBreadcrumbs('Cloudflare', 'settings');
  const dispatch = useDispatch();
  const [modalLoading, setModalLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [zonesMode, setZonesMode] = useState(false);
  const [zones, setZones] = useState([]);
  const [modal, setModal] = useState(false);
  const modalDialog = useModal();
  const [accounts, setAccounts] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null); // {account_id: '', name: ''}
  const allWebsites = useSelector(websitesSelector);
  const confirm = useConfirm();

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line
  }, []);

  const createCloudflareLink = (path) => `https://dash.cloudflare.com/${path}`;
  const createCloudflareZoneLink = (zone) => createCloudflareLink(`${zone.account_id}/${zone.name}`);

  const handleErrorResponse = (error) => {
    dispatch(setGlobalErrorMsg(error));
    DialogHelper.error(modalDialog, getErrorMsg(error));
  }

  // ------------------ WEBSITE ------------------

  const getAssignableWebsiteOptions = () => {
    const assignedWebsites = allWebsites.filter(w => !isEmptyOrNull(WebsiteHelper.getCloudflareZone(w)));
    return WebsiteHelper.buildSelectOptions(allWebsites.filter(w =>
      !w.is_staging &&
      !assignedWebsites.includes(w) &&
      isEmptyOrNull(w.cdns)
    ));
  }

  // ------------------ ACCOUNT ------------------

  const fetchAccounts = () => {
    setTableLoading(true);
    CloudflareService.listAccounts()
      .then(data => setAccounts(data))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  };

  const connectAccount = () => {
    setModalLoading(true);
    CloudflareService.connectAccount({ api_key: modal.api_key })
      .then(newAccounts => {
        setAccounts(prev => [...prev, ...newAccounts]);
        setModal(false);
      })
      .catch(handleErrorResponse)
      .finally(() => setModalLoading(false))
  };

  const disconnectAccount = account => {
    setTableLoading(true);
    CloudflareService.disconnectAccount({ account_id: account.account_id })
      .then(() => setAccounts(prev => prev.filter(item => item.account_id !== account.account_id)))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  }

  const updateAccountAPIToken = account => {
    CloudflareService.updateAccountAPIKey({ account_id: modal.account_id, api_key: modal.api_key })
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'Cloudflare Account', action: 'updated', id: account.name }));
        setModal(false);
      })
      .catch(handleErrorResponse)
  }

  const backToAccounts = () => {
    setZonesMode(false);
    fetchAccounts();
  }

  const onClickConnectZone = () => {
    setModal({ name: 'connect-zone', account_id: currentAccount.account_id });
    if (currentAccount.assignable_zones) {
      return;
    }
    CloudflareService.listAccountAssignableZones({ account_id: currentAccount.account_id })
      .then(assignableZones => {
        setCurrentAccount(prev => ({ ...prev, assignable_zones: assignableZones }))
        if (isEmptyOrNull(assignableZones)) {
          DialogHelper.warning(modalDialog, `No ${CloudflareHelper.LABEL_DNS_SERVICE} Available`, `All ${CloudflareHelper.LABEL_DNS_SERVICE} profiles seem to be already connected to ${env.getBrandShortName()} websites.`);
          setModal(false);
        }
      }).catch(handleErrorResponse);
  }

  const accountActions = [
    {
      value: 'Go to Dashboard',
      onClick: item => UrlHelper.open(createCloudflareLink(`${item.account_id}`))
    },
    {
      value: 'Connected Websites',
      onClick: item => loadAccountZones(item),
    },
    {
      value: 'Update API Token',
      onClick: item => setModal({ name: 'update-api-token', api_key: '', account_id: item.account_id })
    },
    {
      value: 'Disconnect',
      onClick: item => DialogHelper
        .confirmAction(confirm, 'disconnect', item.name, 'Cloudflare Account', `This will disconnect your account from the ${env.getBrandShortName()} dashboard, but it will not affect the account on Cloudflare's end.`)
        .then(() => disconnectAccount(item))
    },
  ];

  const accountHeaders = [
    {
      name: 'Name',
      selector: 'name',
      searchable: true,
      width: '50%',
      cell: row => JsxHelper.createTableMultiLineCell({
        header: row.name,
        headerLink: () => loadAccountZones(row),
        subheader: (row.type === 'standard' ? 'Free' : StringHelper.capitalizeFirstLetter(row.type)) + ' Account',
      })
    },
    JsxHelper.createTableTextHeaderWithCallback('type', 'Cloudflare Connections', '30%', (row) =>
      <span style={{marginTop: '5px'}}>
        {row.stats.total_zones} {StringHelper.maybePluraize('website', row.stats.total_zones)} connected
        {row.stats.pending_zones > 0 ? JsxHelper.createWarningBox(`${row.stats.pending_zones} ${StringHelper.maybePluraize('zone', row.stats.pending_zones)} pending verification`, true) : null}
      </span>
    ),
    JsxHelper.createTableActionsHeader(accountActions, '20%'),
  ];

  const renderConnectSteps = () =>
    <div>
      <p className='above-header'>
        Follow the steps below to connect your Cloudflare account to the {env.getBrandShortName()} dashboard.<br />
        For more information see the {JsxHelper.createFaqLink('integrating-cloudflare-with-staq', 'Cloudflare Integration Guide')}.
      </p>
      <div className='steps'>
        <p>1. From the {JsxHelper.createLink(createCloudflareLink('/profile/api-tokens/'), 'Cloudflare dashboard')}, go to <b>My Profile</b> {JsxHelper.ARROW_SYMBOL} <b>API Tokens</b>.</p>
        <p>2. Click on <b>Create Token</b>.</p>
        <p>3. Click on <b>Use template</b> in the <b>Create Additional Tokens	</b> section.</p>
        <p>4. Give the token a name (e.g. {env.getBrandShortName()}-Token). (Optional)</p>
        <p>5. Add the following permissions on top of the default permissions:</p>
        <ul style={{ listStyle: 'disc', marginLeft: '25px' }}>
          <li><b>Account</b> {JsxHelper.ARROW_SYMBOL} <b>Account Settings</b> {JsxHelper.ARROW_SYMBOL} <b>Read</b></li>
          <li><b>Zone</b> {JsxHelper.ARROW_SYMBOL} <b>DNS</b> {JsxHelper.ARROW_SYMBOL} <b>Edit</b></li>
          <li><b>Zone</b> {JsxHelper.ARROW_SYMBOL} <b>Zone</b> {JsxHelper.ARROW_SYMBOL} <b>Edit</b></li>
        </ul>
        <p>6. Under <b>Account Resources</b> select <b>Include</b> {JsxHelper.ARROW_SYMBOL} <b>All accounts</b>.</p>
        <p style={{fontSize: '12.5px', fontStyle: 'italic'}}><b>Note:</b> Select <b>[Account Name]</b> if you want to restrict the token to a specific account.</p>
        <p>7. Click on <b>Continue to summary</b>.</p>
        <p>8. Review the permissions and click on <b>Create Token</b>.</p>
        <p>9. Copy the token and paste it in the field below.</p>
      </div>
    </div>

  // ------------------ ZONE ------------------

  const createZone = () => {
    setModalLoading(true);
    const data = { account_id: modal.account_id, website_slug: modal.website_slug, name: modal.zone_name };
    dispatch(createWebsiteCloudflareZone(data))
    .then(website => {
      setZones(prev => [...prev, website.cloudflare_zone]);
      setModal(false);
    })
    .catch(handleErrorResponse)
    .finally(() => setModalLoading(false));
  };

  const ConnectZone = () => {
    setModalLoading(true);
    const data = { account_id: modal.account_id, new_zone_id: modal.zone_id, website_slug: modal.website_slug };
    dispatch(connectWebsiteCloudflareZone(data)).then(website => {
      setZones(prev => [...prev, website.cloudflare_zone]);
      setCurrentAccount(prev => ({ ...prev, assignable_zones: prev.assignable_zones.filter(z => z.zone_id !== website.cloudflare_zone.zone_id) }));
      setModal(false);
    })
    .catch(handleErrorResponse)
    .finally(() => setModalLoading(false));
  };

  const optimizeZoneSettings = (item) => {
    dispatch(setGlobalPleaseWaitMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'optimized', id: item.name }));
    CloudflareService.optimizeZoneSettings({ zone_id: item.zone_id, website_slug: item.website_slug })
      .then(() => dispatch(setGlobalSuccessMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'optimized', id: item.name })))
      .catch(handleErrorResponse)
  }

  const syncZone = (item, checkNameservers) => {
    checkNameservers = checkNameservers || false;
    dispatch(setGlobalPleaseWaitMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'synced', id: item.name }));
    const data = { zone_id: item.zone_id, website_slug: item.website_slug };
    const method = checkNameservers ? syncWebsiteCloudflareZone : refreshWebsiteCloudflareZone;
    dispatch(method(data))
      .then((website) => {
        const zone = WebsiteHelper.getCloudflareZone(website);
        setZones(prev => prev.map(_zone => _zone.zone_id === zone.zone_id ? zone : _zone))
        dispatch(setGlobalSuccessMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'synced', id: zone.name }));
        if (checkNameservers && zone.status === 'pending') {
          DialogHelper.warning(
            modalDialog,
            'Nameservers Update Required',
            `Follow the <a class="goto-link" href="${createCloudflareZoneLink(zone)}" target="_blank">instructions</a> to update your domain's nameservers.`
          );
        }
      })
      .catch(handleErrorResponse)
  }

  const deleteZone = (item, disconnectOnly) => {
    const action = disconnectOnly ? 'disconnect' : 'delete';
    const message = CloudflareHelper.getDeleteZoneConfirmation(item.name, disconnectOnly);
    DialogHelper
      .confirmAction(confirm, action, item.name, CloudflareHelper.LABEL_WEBSITE_SERVICE, 'Warning: ' + message)
      .then(() => {
        setTableLoading(true);
        CloudflareService.deleteZone({ website_slug: item.website_slug, zone_id: item.zone_id, disconnect_only: disconnectOnly })
          .then(() => {
            dispatch(fetchWebsite({ website_slug: item.website_slug }));
            setZones(prev => prev.filter(z => z.zone_id !== item.zone_id))
            delete item.assignable_zones;
          })
          .catch(handleErrorResponse)
          .finally(() => setTableLoading(false));
      });
  }

  const loadAccountZones = (account) => {
    let data = {}
    if (account) {
      setCurrentAccount({ account_id: account.account_id, name: account.name });
      data.account_id = account.account_id;
    }
    setZonesMode(true);
    setTableLoading(true);
    CloudflareService.listZones(data)
      .then(data => setZones(data))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  };

  const zonesActions = [
    {
      value: 'Check Nameservers',
      onClick: (item) => syncZone(item, true),
    },
    {
      value: 'Refresh Details',
      onClick: syncZone,
    },
    {
      value: 'Optimize Settings',
      onClick: optimizeZoneSettings,
    },
    {
      value: 'Purge Cache',
      onClick: (item) => {
        dispatch(setGlobalPleaseWaitMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'cache purged', id: item.name }));
        CloudflareService.purgeZoneCache({ zone_id: item.zone_id, website_slug: item.website_slug })
          .then(() => {
            dispatch(setGlobalSuccessMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'cache purged', id: item.name }))
            DialogHelper.success(modalDialog, 'Cached Purged', 'Please allow a few seconds for changes to take effect.')
          })
          .catch(handleErrorResponse)
      },
      doHide: (item) => item.status !== 'active',
    },
    {
      value: 'Disconnect',
      onClick: (item) => deleteZone(item, true)
    },
    {
      value: 'Delete',
      onClick: (item) => deleteZone(item, false),
    }
  ];

  const zonesHeaders = [
    {
      name: `${env.getBrandShortName()} Website`,
      selector: 'website',
      searchable: true,
      width: '25%',
      cell: (row) => {
        const website = allWebsites.find(w => w.slug === row.website_slug);
        if (website) {
          TableHelper.customizeCellValue(row, 'website', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
        }
        return JsxHelper.createTableWebsiteCell({
          website,
          paddingRight: '10px',
          marginRight: '15px',
        })
      }
    },
    {
      name: 'Cloudflare DNS',
      selector: 'name',
      searchable: true,
      width: '25%',
      cell: row => JsxHelper.createTableMultiLineCell({
        header: row.name,
        subheader: accounts.find(account => account.account_id === row.account_id)?.name,
        headerLink: createCloudflareZoneLink(row),
        subheaderLink: createCloudflareLink(`${row.account_id}`),
      })
    },
    JsxHelper.createTableStatusHeader('status', null, true, '15%'),
    JsxHelper.createTableDNSRecordsHeader(dispatch, 'Name Servers', '25%', (row) => ({ type: 'NS', values: row.name_servers })),
    JsxHelper.createTableActionsHeader(zonesActions, '10%'),
  ];

  const renderCreateZoneHeader = () => <p>
    Select a Cloudflare account to automatically configure a new {CloudflareHelper.LABEL_DNS_SERVICE} for your {env.getBrandShortName()} website.
  </p>

  const renderConnectZoneHeader = () => <p>
    Select a Cloudflare account to connect an existing {CloudflareHelper.LABEL_DNS_SERVICE} to your {env.getBrandShortName()} website.
  </p>

  return (
    <div className='global-settings'>
      <TitleBar className='titlebar'>
        <TitleBar.Title breadcrumbs={zonesMode ? breadcrumbs.concat([{text: 'Accounts', link: '#'}]) : breadcrumbs}>
          {zonesMode ? currentAccount.name : 'Cloudflare Accounts'}
        </TitleBar.Title>
        <TitleBar.Actions>
          {zonesMode && JsxHelper.createButton({
            label: 'Add New DNS',
            onClick: () => setModal({ name: 'create-zone', account_id: currentAccount.account_id, zone_name: '' }),
          })}
          {zonesMode && currentAccount && JsxHelper.createButton({
            label: 'Connect Existing DNS',
            classes: 'alt--btn',
            onClick: onClickConnectZone,
          })}
          {!zonesMode && JsxHelper.createButton({
            label: 'Connect Account',
            onClick: () => setModal({ name: 'create-account', api_key: '' }),
          })}
          {!zonesMode ? JsxHelper.createBackButton() : JsxHelper.createButton({ label: 'Back', onClick: backToAccounts })}
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
      {env.getBrandShortName()} offers a seamless integration with <b>{JsxHelper.createLink('https://cloudflare.com', 'Cloudflare')}</b> that enables you to connect your websites with Cloudflare without leaving the dashboard.
      </p>
      <Content>
        {!zonesMode && <div id='cf-accounts' style={{width: '60%'}}>
          <WPSDataTable
            columns={accountHeaders}
            body={accounts}
            noSearchOnTable={true}
            hidePagination={true}
            loading={tableLoading}
          />
        </div>}
        {zonesMode && <div id='cf-zones'>
          <WPSDataTable
            columns={zonesHeaders}
            body={zones}
            noSearchOnTable={true}
            hidePagination={true}
            loading={tableLoading}
          />
        </div>}
      </Content>
      {modal && modal.name === 'create-account' && DialogHelper.inputs({
        title: 'Connect Cloudflare Account',
        className: 'instruct-modal',
        onClose: () => setModal(false),
        onConfirm: connectAccount,
        loading: modalLoading,
        icon: 'cloudflare',
        iconColor: 'info',
        confirmBtn: 'Save',
        confirmColor: 'success',
        disabled: isEmptyOrNull(modal.api_key),
        header: renderConnectSteps(),
        inputs: [{
          label: 'API Token',
          name: 'api_key',
          placeholder: 'Enter your Cloudflare API Token',
          value: modal.api_key,
          onChange: e => setModal({ ...modal, api_key: e.target.value }),
          required: true,
        },
      ]})}
      {modal && modal.name === 'update-api-token' && DialogHelper.inputs({
        title: 'Update Account API Token',
        onClose: () => setModal(false),
        onConfirm: updateAccountAPIToken,
        loading: modalLoading,
        confirmBtn: 'Update',
        disabled: isEmptyOrNull(modal.api_key),
        inputs: [{
          label: 'API Token',
          name: 'api_key',
          placeholder: 'Enter your Cloudflare API Token',
          value: modal.api_key,
          onChange: e => setModal({ ...modal, api_key: e.target.value }),
          required: true,
        },
      ]})}
      {modal && modal.name === 'create-zone' && DialogHelper.inputs({
        title: 'Add New ' + CloudflareHelper.LABEL_DNS_SERVICE,
        onClose: () => setModal(false),
        onConfirm: createZone,
        loading: modalLoading,
        confirmBtn: 'Create',
        disabled: isEmptyOrNull(modal.account_id) || isEmptyOrNull(modal.website_slug) || isEmptyOrNull(modal.zone_name),
        header: renderCreateZoneHeader(),
        inputs: [{
          label: 'Cloudflare Account',
          name: 'account_id',
          type: 'select',
          disabled: true,
          value: modal.account_id,
          onChange: e => setModal({ ...modal, account_id: e.target.value }),
          options: accounts.map(item => ({ value: item.account_id, label: item.name })),
          required: true,
        },
        {
          label: `${env.getBrandShortName()} Website`,
          name: 'website_slug',
          type: 'select',
          value: modal.website_slug,
          onChange: e => {
            const slug = e.target.value;
            const website = allWebsites.find(w => w.slug === slug);
            setModal({
              ...modal,
              website_slug: slug,
              zone_name: website && website.is_live ? website.registrable_domain : ''
            })
          },
          options: getAssignableWebsiteOptions(),
          required: true,
        },
        {
          label: 'Domain Name',
          name: 'zone_name',
          type: 'text',
          placeholder: 'example.com',
          value: modal.zone_name,
          onChange: e => setModal({ ...modal, zone_name: e.target.value }),
          required: true,
        },
      ]})}
      {modal && modal.name === 'connect-zone' && DialogHelper.inputs({
        title: 'Connect Existing ' + CloudflareHelper.LABEL_DNS_SERVICE,
        onClose: () => setModal(false),
        onConfirm: ConnectZone,
        loading: modalLoading,
        header: renderConnectZoneHeader(),
        confirmBtn: 'Connect',
        disabled: isEmptyOrNull(modal.zone_id) || isEmptyOrNull(modal.website_slug),
        inputs: [{
          label: 'Select Cloudflare Account',
          name: 'account_id',
          type: 'select',
          value: modal.account_id,
          options: ArrayHelper.buildSelectOptions([currentAccount], 'name', 'account_id'),
          disabled: true,
          required: true,
        },
        {
          label: 'Select ' + CloudflareHelper.LABEL_DNS_SERVICE,
          name: 'zone_id',
          type: 'select',
          value: modal.zone_id,
          onChange: e => setModal({ ...modal, zone_id: e.target.value }),
          placeholder: isUndefined(currentAccount.assignable_zones) ? 'Loading...' : 'Select a Website',
          disabled: isUndefined(currentAccount.assignable_zones),
          options: ArrayHelper.buildSelectOptions(currentAccount.assignable_zones || [], 'name', 'zone_id'),
          required: true,
        },
        {
          label: `${env.getBrandShortName()} Website`,
          name: 'website_slug',
          type: 'select',
          value: modal.website_slug,
          onChange: e => setModal({ ...modal, website_slug: e.target.value }),
          options: getAssignableWebsiteOptions(),
          required: true,
        },
      ]})}
    </div>
  );
};

export default Cloudflare;
