import React, { useEffect, useRef } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setGlobalErrorMsg, } from 'store/global/globalActions';
import WebsiteService from 'services/website';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import FirewallBannedIPs from 'components/security/FirewallBannedIPs';
import StringHelper from 'helpers/string';
import DialogHelper from 'helpers/dialog';
import UserHelper from 'helpers/user';
import env from 'config/env';
import { isEmptyOrNull } from 'helpers';
import JsxHelper from 'helpers/jsx';

const BannedIPs = ({ website }) => {
  useTitle('Firewall - Banned IPs');
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const PAGE_SIZE = 100;
  const DAY_IN_HOURS = 24;
  const [modal, setModal] = useState(false);
  const [fetchLoading, setFetchLoading]  = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [firewallBannedIPs, setFirewallBannedIPs] = useState([]);
  const [fetchParams, setFetchParams] = useState({
    page: 1,
    limit: PAGE_SIZE,
    hasMore: true,
  });

  // ------------------------------
  // Lifecycle functions
  // ------------------------------

  useEffect(() => {
    if (mounted.current) {
      fetchBannedIPs();
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // ------------------------------
  // API Calls
  // ------------------------------

  const fetchFirewallBannedIPs = (page) => {
    const data = { website_slug: website.slug, ...fetchParams };
    if (page) {
      data.page = page;
    }
    WebsiteService.listBannedIPs(data)
    .then(res => {
      if (isEmptyOrNull(res)) {
        setFetchParams(prev => ({ ...prev, fetched: true, hasMore: false }));
        return;
      }
      setFirewallBannedIPs(prev => prev.concat(res.map(item => {
        item.id = StringHelper.randomSlug();
        return item;
      })));
      setFetchParams(prev => ({
        ...prev,
        page: data.page + 1,
        fetched: true,
        hasMore: res.length >= PAGE_SIZE,
      }));
    })
    .catch(err => dispatch(setGlobalErrorMsg(err)))
    .finally(() => {
      setFetchLoading(false);
      setRefreshLoading(false);
    });
  }

  const canLoadMore = () => fetchParams.hasMore;

  const refreshBannedIPs = () => {
    setFirewallBannedIPs([]);
    setFetchParams(prev => ({ ...prev, page: 1, fetched: false }));
    setRefreshLoading(true);
    fetchBannedIPs(1); // Reset pages
  }

  const openBanModal = () => setModal({ ip: '', ban_hours: DAY_IN_HOURS * 3, loading: false });

  const createManualIPBan = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const reason = `Banned by ${UserHelper.getEmail()} via ${env.getBrandShortName()} dashboard.`
    WebsiteService.banIP({ website_slug: website.slug, ip_address: modal.ip, ban_hours: modal.ban_hours, reason })
    .then(() => {
      refreshBannedIPs();
      setModal(false);
    })
    .catch(err => {
      dispatch(setGlobalErrorMsg(err));
      setModal(prev => ({ ...prev, loading: false }));
    });
  }

  const loadMoreBannedIPs = () => {
    fetchBannedIPs(fetchParams.page);
  }

  const fetchBannedIPs = (page) => {
    setFetchLoading(true);
    fetchFirewallBannedIPs(page);
  }

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>
          <div>Firewall</div>
        </TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({
            label: 'Ban IP Address',
            classes: 'alt--btn',
            disabled: fetchLoading || refreshLoading || modal.loading,
            loading: modal.loading,
            onClick: openBanModal,
          })}
          {JsxHelper.createButton({
            label: 'Refresh',
            disabled: fetchLoading || refreshLoading,
            loading: refreshLoading,
            onClick: refreshBannedIPs,
          })}
          {JsxHelper.createButton({
            label: 'Load More',
            disabled: !canLoadMore() || fetchLoading || refreshLoading,
            loading: !refreshLoading && fetchLoading,
            onClick: loadMoreBannedIPs,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
      Gain valuable insights into malicious traffic on your website with a detailed record of banned IP addresses. This includes IPs blocked at the server level by the <strong>{env.getBrandShortName()} Firewall</strong> and those blocked at the website level through <strong>User-Defined Ban Rules</strong> in the <strong>{env.getBrandShortName()} Hosting</strong> plugin. Easily review and report safe IPs to unblock them from the firewall.
      </p>
      <Content>
        <FirewallBannedIPs
          dispatch={dispatch}
          loading={fetchLoading}
          setTableData={setFirewallBannedIPs}
          tableData={firewallBannedIPs}
          rowsPerPage={25}
        />
        {modal && DialogHelper.inputs({
          title: 'Ban IP Address',
          header: <p>Manually ban an IP address from accessing your website.</p>,
          loading: modal.loading,
          onClose: () => setModal(false),
          onConfirm: createManualIPBan,
          confirmBtn: 'Confirm',
          inputs: [
          {
            label: 'IP Address',
            name: 'ip',
            value: modal.ip,
            placeholder: 'Enter IP Address',
            onChange: (e) => setModal(prev => ({ ...prev, ip: e.target.value })),
          },
          {
            type: 'select',
            label: 'Ban Duration',
            name: 'ban_hours',
            value: modal.ban_hours,
            options: [
              { label: '1 Day', value: DAY_IN_HOURS },
              { label: '3 Days', value: DAY_IN_HOURS * 3 },
              { label: '1 Week', value: DAY_IN_HOURS * 7 },
              { label: '1 Month', value: DAY_IN_HOURS * 30 },
              { label: '3 Months', value: DAY_IN_HOURS * 90 },
              { label: '6 Months', value: DAY_IN_HOURS * 180 },
              { label: '1 Year', value: DAY_IN_HOURS * 365 },
            ],
            onChange: (e) => setModal(prev => ({ ...prev, ban_hours: e.target.value })),
          }
        ]})}
      </Content>
    </Container>
  );
};

export default BannedIPs;
